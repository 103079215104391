import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { RequestForm } from '@components/RequestForm/RequestForm';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { RequestFormDefaultValues } from '@components/RequestForm/RequestFormDefaultValues';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { RequestFormStatus } from '@interfaces/RequestForm/RequestFormStatus';
import RequestFormSelector from '@store/selectors/RequestFormSelector';
import { resetForm } from '@store/actions/RequestFormActionCreators';
import usePageEffects from '@utilities/hooks/usePageEffects/usePageEffects';
import { ToastConstants } from '@utilities/toastConstants';
import RequestFormPageState from '@components/RequestForm/RequestFormPageState';
import { surgeryRequestApiToFormState } from '@utilities/typeConverters/surgeryRequestApiToFormState';
import copyCaseSections from '@components/RequestForm/utilities/copyCaseSections';
import useSubmitRequest from '@data/request/useSubmitRequest';
import { isWebRequest } from '@utilities/typeAssertionUtilities';
import { AppointmentType } from '@data/request/AppointmentType';
import useDoesUnitSupportAppointmentTypes from '@utilities/hooks/useDoesUnitSupportAppointmentTypes/useDoesUnitSupportAppointmentTypes';
import { ATTACHMENT_REQUEST_TYPES } from '@utilities/constants';
import { DocumentSchema } from '@utilities/Validation/validationSchema';
import { FormMetaProvider } from '@store/context/FormMetaContext';
import { dateParse, parsedTimeTo24Hr } from '@utilities/dateUtilities';
import { BackLink } from '@components/BackLink/BackLink';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import GoldButton from '@components/GoldButton/GoldButton';
import { ModalContextProvider } from '@components/Modal/ModalContextProvider';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { useGetSurgeonOptions } from '@utilities/hooks/useGetSurgeonOptions/useGetSurgeonOptions';
import {
	useNavigationHistory
} from '@components/RequestForm/utilities/hooks/useNavigationHistory/useNavigationHistory';

export const GENERIC_UI_ERROR = 'Please check the page for errors.';
const formErrors: { [serviceError: string]: string } = {
	'409 - Duplicate surgery request':
		'We have found an existing request with the same doctor, patient, date, and time. Please review your request.',
	'422 - No primary procedure found':
		'There should be a primary procedure selected.',
	[GENERIC_UI_ERROR]: GENERIC_UI_ERROR,
};

const useStyles = makeStyles((theme) =>
	createStyles({
		buttonContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			gap: theme.spacing(2),
		},
	}),
);

export function RequestFormPage() {
	const classes = useStyles();
	const history = useHistory<RequestFormPageState>();
	const lastCaseInfo = useSelector(CaseDetailsInfoSelector);
	const dispatch = useDispatch();
	const [showSecondaryInsurance, setShowSecondaryInsurance] = React.useState(false);
	const { handleGoBack } = useNavigationHistory();

	const options  = useGetSurgeonOptions();

	const defaultValues = React.useMemo(() => {
		if (history.location.state?.blockInfo) {
			const defaultValsFromSchedulePage = {
				...RequestFormDefaultValues,
				appointmentType: AppointmentType.LEGACY_WEB,
				primarySurgeon: history.location.state.blockInfo.surgeon?.id
			};

			const parsedProcedureDate = history.location.state.blockInfo.toFollowTime && dateParse(history.location.state.blockInfo.toFollowTime);
			defaultValsFromSchedulePage.procedureDate = parsedProcedureDate ? history.location.state.blockInfo.toFollowTime : history.location.state.blockInfo.selectedDate;

			if (parsedProcedureDate) {
				const procedureTime24Hr = parsedTimeTo24Hr(parsedProcedureDate[1], parsedProcedureDate[2]);
				defaultValsFromSchedulePage.procedureTime = [procedureTime24Hr, parsedProcedureDate[1]] as [string, string];
			}
			return defaultValsFromSchedulePage;
		}

		if (options.length > 0) {
			RequestFormDefaultValues.primarySurgeon = options[0].value;
		}

		if (!history.location.state || !history.location.state.copyFrom) {
			return RequestFormDefaultValues;
		}

		if (isWebRequest(lastCaseInfo)) {
			if (lastCaseInfo.insurances && lastCaseInfo.insurances.length > 1) {
				setShowSecondaryInsurance(true);
			}
		}

		const formCaseInfo = surgeryRequestApiToFormState(lastCaseInfo);
		return copyCaseSections(formCaseInfo, history.location.state.copyFrom.sections);
	}, [options, lastCaseInfo, history]);


	const formMethods = useForm<RequestFormValidatedState>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		criteriaMode: 'all',
		defaultValues,
	});

	const { formStatus, formError } = useSelector(RequestFormSelector);

	const {
		formState: { errors },
		reset,
		watch,
		clearErrors,
	} = formMethods;
	const [appointmentLocation = ''] = watch(['appointmentLocation']);

	const selectedLocationSupportsAttachments =
		useDoesUnitSupportAppointmentTypes(
			appointmentLocation,
			ATTACHMENT_REQUEST_TYPES,
		);
	const defaultAppointmentType = selectedLocationSupportsAttachments
		? AppointmentType.WEB
		: AppointmentType.LEGACY_WEB;

	const handleResetForm = useCallback(() => {
		dispatch(resetForm());
		reset({
			...RequestFormDefaultValues,
			appointmentType: defaultAppointmentType,
		});
		setShowSecondaryInsurance(false);
		window.scrollTo(0, 0);
	}, [defaultAppointmentType, dispatch, reset]);

	usePageEffects('Surgery Request Form');

	const handlePostSubmit = useCallback(() => {
		if (history.location.state?.blockInfo) {
			history.push('/scheduling');
		}
		handleResetForm();
	}, [handleResetForm, history]);

	const { save: saveAuthenticatedForm, formId } = useSubmitRequest({
		isNew: true,
		successDelayMs: history.location.state?.blockInfo ? 1000 : 0,
		successToastMessage: ToastConstants.REQUEST_SUCCESS,
		errorToastMessage: ToastConstants.SAVE_ERROR,
		onSuccess: handlePostSubmit
	});

	const hasRhfError = errors && Object.keys(errors).length > 0;
	const userError = hasRhfError ? GENERIC_UI_ERROR : null;
	const serviceError = formError
		? formErrors[formError] ||
			'There was a problem submitting your request. Please try again later. ' +
				formError
		: null;
	const showError = userError || serviceError;

	React.useEffect(() => {
		if (!appointmentLocation && hasRhfError) {
			clearErrors();
		}
	}, [clearErrors, appointmentLocation, hasRhfError]);

	return (
		<ModalContextProvider>
			<Box>
				<BackLink />
				<h1>Surgery Request</h1>
				<FormMetaProvider schema={DocumentSchema} {...formMethods}>
					<RequestForm
						formId={formId}
						showSecondaryInsurance={showSecondaryInsurance}
						setShowSecondaryInsurance={setShowSecondaryInsurance}
					/>
					<Box className={classes.buttonContainer}>
						<Box>
							{showError && (
								<ErrorMessage
									errorField={{
										message: (userError ||
											serviceError) as string,
									}}
								/>
							)}
						</Box>
						{appointmentLocation && (
							<BlueOutlineButton
								data-field="request-form-cancel"
								onClick={handleGoBack}
							>
								Cancel
							</BlueOutlineButton>
						)}
						{appointmentLocation && (
							<GoldButton
								onClick={
									!showError
										? formMethods.handleSubmit(
											saveAuthenticatedForm,
										)
										: undefined
								}
								id="request-form-save"
							>
								{formStatus === RequestFormStatus.LOADING
									? 'Loading...'
									: 'Submit Surgery Request'}
							</GoldButton>
						)}
					</Box>
				</FormMetaProvider>
			</Box>
		</ModalContextProvider>
	);
}
