import PeopleIcon from '@material-ui/icons/People';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { CalendarToday, ExitToApp, Settings } from '@material-ui/icons';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { HospitalInteractiveRoles, OfficeManagerRoles } from '@interfaces/UserProfile/UserRoleEnum';
import usePreferredUnits from '@components/UnitCalendarPage/utilities/usePreferredUnits';
import unitHasOpenTimeConfig from '@components/UnitCalendarPage/utilities/unitHasOpenTimeConfig';
import { useHistory } from 'react-router-dom';
import { useInteractiveLogout } from '@utilities/hooks/useInteractiveLogout/useInteractiveLogout';
import React from 'react';
import {
	UsePreferencesMenuReturnType
} from '@components/HeaderSection/NavigationOptions/PreferencesMenu/utilities/UsePreferencesMenuConfigReturnType';

const usePreferencesMenuConfig: () => UsePreferencesMenuReturnType = () => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const hasPrefUnitsWithOpenTimeConfig =
		usePreferredUnits(unitHasOpenTimeConfig).list.length > 0;

	const handleSendFeedback = () => {
		window.open(
			'https://ascensionsurgeryschedulingmanager.ideas.aha.io/',
			'feedback',
			'noopener,noreferrer',
		);
		setAnchorEl(null);
	};

	const history = useHistory();

	const changeRoute = React.useCallback((path: string) => {
		history.push(path);
		setAnchorEl(null);
	}, [history]);

	const logout = useInteractiveLogout();

	const options = React.useMemo(() => [
		{
			id: 'accountSettings',
			label: 'Account settings',
			icon: Settings,
			onClick: () => changeRoute('/accountSettings/myInformation'),
		}, {
			id: 'sendFeedback',
			label: 'Send us feedback',
			icon: FeedbackIcon,
			onClick: handleSendFeedback,
		}, {
			id: 'or-availability-calendar',
			label: 'OR availability calendar',
			icon: CalendarToday,
			showForRoles: HospitalInteractiveRoles,
			shouldHide: !hasPrefUnitsWithOpenTimeConfig,
			onClick: () => changeRoute('/unit/calendar'),
		}, {
			id: 'manageUsers',
			label: 'Manage Users',
			icon: PeopleIcon,
			showForRoles: OfficeManagerRoles,
			onClick: () => changeRoute('/users')
		}, {
			id: 'addUsers',
			label: 'Add Users',
			icon: PersonAdd,
			showForRoles: OfficeManagerRoles,
			onClick: () => changeRoute('/users/register'),
		}, {
			id: 'logOut',
			label: 'Log out',
			icon: ExitToApp,
			onClick: logout,
		},
	], [changeRoute, hasPrefUnitsWithOpenTimeConfig, logout]);

	return {
		options,
		anchorEl,
		setAnchorEl,
	};
};

export default usePreferencesMenuConfig;
