import React, { ChangeEvent } from 'react';
import { Box, Flex, Label } from '@ascension/web';
import { Box as MuiBox, makeStyles } from '@material-ui/core';
import { AppointmentDetailsValidationSchema } from '@components/RequestForm/AppointmentDetails/AppointmentDetailsValidationSchema';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { useTimeFormContext } from '@utilities/hooks/useTimeFormContext/useTimeFormContext';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useMultiFormValueSetter } from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapter';
import { useManageHoldEventContext } from '@components/RequestForm/ManageHoldEventContext/ManageHoldEventContext';
import { WarningModalType } from '@components/RequestForm/ManageHoldEventContext/ManageHoldEventContextType';
import { Typography } from '@material-ui/core';
import PrimaryCheckbox from '@components/PrimaryCheckbox/PrimaryCheckbox';
import TimePicker from '@components/TimePicker/TimePicker';
import { Duration } from '@components/RequestForm/inputs/Duration/Duration';
import {
	useDoesUnitSupportOpenTime
} from '@components/RequestForm/utilities/hooks/useDoesUnitSupportOpenTime/useDoesUnitSupportOpenTime';

const useStyles = makeStyles((theme) => ({
	timeWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: theme.spacing(2)
	},
	toFollow: {
		marginTop: theme.spacing(-1)
	},
	durationWrapper: {
		marginBottom: theme.spacing(4),
	},
}));

export default function AppointmentDetails () {
	const classes = useStyles();
	const { register, watch } = useFormContext<RequestFormValidatedState>();
	const [ room ] = watch(['room']);
	const hasOpenTime = useDoesUnitSupportOpenTime();

	const timeSetter = useMultiFormValueSetter('procedureTime');
	const dateSetter = useMultiFormValueSetter('procedureDate');

	const {
		currentPeriodValue,
		currentTimeValue,
		setPeriodValue,
		setTimeValue,
		timeError,
		triggerTimeValidation,
	} = useTimeFormContext('procedure', {
		timePickerValidation: AppointmentDetailsValidationSchema.appointmentTime,
		timeSetter,
		dateSetter,
	});

	const toFollowSetCurrentValue = useMultiFormValueSetter('appointmentToFollow');
	const {
		currentValue: toFollowCurrentValue,
	} = useFieldFormContext<boolean>('appointmentToFollow');

	const blockTimeSetCurrentValue = useMultiFormValueSetter('appointmentBlockTime');
	const {
		currentValue: blockTimeCurrentValue,
	} = useFieldFormContext<boolean>('appointmentBlockTime');

	React.useEffect(() => {
		register('holdEventId');
		register('room');
		register('roomId');
	}, [register]);

	const { handleOpenWarningModal, setWarningType, setNewFieldValue, setPrevFieldValue } = useManageHoldEventContext();

	const handleBlockTimeChange = React.useCallback((event: ChangeEvent, newValue: boolean) => {
		// if a hold event exists, block time value should be set conditionally
		if (room) {
			// set warning type to block time
			setWarningType(WarningModalType.BLOCK_TIME);
			// set prev and current values so value setting can be managed via warning modal
			setPrevFieldValue(blockTimeCurrentValue);
			setNewFieldValue(newValue);
			// open the warning modal
			handleOpenWarningModal();
		} else {
			// else just set the field value
			blockTimeSetCurrentValue(newValue);
		}
	}, [blockTimeCurrentValue, blockTimeSetCurrentValue, handleOpenWarningModal, room, setNewFieldValue, setPrevFieldValue, setWarningType]);

	const handleToFollowChange = React.useCallback((event: ChangeEvent, value: boolean | undefined) => {
		toFollowSetCurrentValue(value);
	}, [toFollowSetCurrentValue]);

	return (
		<>
			{!hasOpenTime &&
				<Box
					mb={13}
				>
					<Label>Are you scheduling this case into Block Time?</Label>
					<Flex
						flexDirection="row"
						flexWrap="wrap"
					>
						<Box
							data-field="Appointment_BlockTime"
						>
							<PrimaryCheckbox
								checked={!!blockTimeCurrentValue}
								handleChange={handleBlockTimeChange}
								label={
									<Typography>
										<strong>Yes</strong>, I am scheduling this case into <strong>Block Time</strong>
									</Typography>
								}
							/>
						</Box>
					</Flex>
				</Box>
			}
			<MuiBox data-field="Appointment">
				<MuiBox className={classes.timeWrapper}>
					<MuiBox
						data-field="Appointment_Time"
					>
						<TimePicker
							label="Requested Time"
							name="appointmentTime"
							value={currentTimeValue}
							required
							periodValue={currentPeriodValue}
							onTimeChange={setTimeValue}
							onPeriodChange={setPeriodValue}
							errorMessage={timeError}
							onBlur={triggerTimeValidation}
						/>
					</MuiBox>
					<MuiBox
						data-field="Appointment_ToFollow"
						className={classes.toFollow}
					>
						<PrimaryCheckbox
							checked={toFollowCurrentValue}
							handleChange={handleToFollowChange}
							label="To Follow"
						/>
					</MuiBox>
				</MuiBox>
				{!hasOpenTime &&
						<MuiBox data-field="Appointment_Duration" className={classes.durationWrapper}>
							<Duration />
						</MuiBox>
				}
			</MuiBox>

		</>
	);
}
