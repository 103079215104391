import React from 'react';
import { useFormContext } from 'react-hook-form';
import { addMinutes, isAfter, isBefore, parse, set } from 'date-fns';
import { AvailableTimeOption } from '@data/findATime/types/AvailableTimeOption';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { useFindATimeContext } from '@components/FindATimeContext/FindATimeContextProvider';

/**
 * A React hook that watches the procedure time and duration in the form context.
 * If the selected time slot is the same as the provided time slot ID,
 * it checks if the selected procedure time and duration fit within the time slot.
 * If they don't, it clears the selected time slot.
 *
 * @param {AvailableTimeOption} time - The time slot to watch.
 * @param {string} timeSlotId - The ID of the time slot to watch.
 *
 * @example
 * useTimeAndDurationWatcher(time, timeSlotId);
 *
 * @returns {void} No return value
 */
export const useTimeAndDurationWatcher = (time: AvailableTimeOption, timeSlotId: string): void => {
	const { watch } = useFormContext<Partial<CommonRequestForm>>();
	const { setSelectedTimeSlot, lastSelectedTimeSlot } = useFindATimeContext();

	const [duration, procedureTime] = watch(['duration', 'procedureTime']);

	// Effect to run when duration, procedure time, or selected time slot changes
	React.useEffect(() => {
		// If no duration or procedure time are set and the last selected time slot is not the one being watched, return
		if (!duration || !procedureTime || timeSlotId !== lastSelectedTimeSlot.current) { return; }

		// Parse the procedure time into hours and minutes
		const [hours, minutes] = procedureTime[0].split(':').map(Number);

		// Calculate the selected date and time
		const selectedDateAndTime = set(
			parse(time.start, 'HH:mm:ss', new Date()),
			{ hours, minutes },
		);

		// Calculate the end time based on the duration
		const durationNum = Number(duration);
		const calculatedEndTime = addMinutes(selectedDateAndTime, durationNum);

		const timeSlotStartTime = parse(time.start, 'HH:mm:ss', new Date());
		const timeSlotEndTime = parse(time.end, 'HH:mm:ss', new Date());

		// If the selected date and time or the calculated end time are outside the time slot...
		if (
			isBefore(selectedDateAndTime, timeSlotStartTime) ||
			isAfter(calculatedEndTime, timeSlotEndTime)
		) {
			// Clear the selected time slot
			setSelectedTimeSlot('');
		} else {
			// Otherwise, set the selected time slot to the one being watched
			setSelectedTimeSlot(timeSlotId);
		}
	}, [
		duration,
		procedureTime,
		setSelectedTimeSlot,
		time.end,
		time.start,
		timeSlotId,
		lastSelectedTimeSlot,
	]);
};
