import React from 'react';
import { FieldValues } from 'react-hook-form';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Box, Multiselect, Label } from '@ascension/web';
import { useFormMeta } from '@store/context/FormMetaContext';
import { DropdownOption } from '@definitions/DropdownOption';
import { checkIsRequired } from '@utilities/formUtilities';
import { FormComponentProps } from './FormComponentProps';
import { useMultiDropdownFormContext } from '@utilities/hooks/useMultiDropdownFormContext/useMultiDropdownFormContext';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const defaultOptions: DropdownOption[] = [];
const useStyles = makeStyles((theme: Theme) => createStyles({
	wrapper: {
		'& span': {
			fontSize: theme.spacing(2),
			fontWeight: 'initial'
		}
	},
	label: {
		'& span': {
			fontSize: theme.spacing(1.5),
			fontWeight: 700
		}
	},
	errorWrapper: {
		'marginTop': theme.spacing(1),
		'& span': {
			fontSize: theme.spacing(1.5),
			fontWeight: 700
		}
	}
}));

export default function FormMultiDropdown<T extends FieldValues>({
	name,
	label,
}: FormComponentProps<T>) {
	const { schema, options } = useFormMeta<T>();
	const providedOptions: DropdownOption[] = Array.isArray(options[name]) ? options[name] as DropdownOption[] : defaultOptions;

	const {
		setSelectedValue,
		dropdownOptions,
		setDropdownOptions,
		error,
		selectedDropdownOption,
	} = useMultiDropdownFormContext(name, providedOptions, { validationRules: schema[name] });

	const isRequired = checkIsRequired(schema[name]);
	const isNonePreviouslySelected = React.useRef(false);
	const handleChange = React.useCallback((selectedOptions: DropdownOption[]) => {
		const isNoneSelected = selectedOptions.some(option => option.value === 'None');
		let newOptions: DropdownOption[] = selectedOptions;
		if (isNoneSelected) {
			newOptions = isNonePreviouslySelected.current ?
				selectedOptions.filter(option => option.value !== 'None') :
				[{ value: 'None', label: 'None' }];
		}

		isNonePreviouslySelected.current = isNoneSelected && !isNonePreviouslySelected.current;

		setSelectedValue(newOptions);
	}, [setSelectedValue]);

	React.useEffect(() => {
		setDropdownOptions(providedOptions);
	}, [providedOptions, setDropdownOptions]);
	const classes = useStyles();

	return (
		<Box width={1 / 2} mt={8} data-field={name} className={classes.wrapper}>
			<Label required={isRequired} className={classes.label}>{label}</Label>
			<Multiselect
				borderless={false}
				value={selectedDropdownOption}
				onChange={handleChange}
				label={!selectedDropdownOption.length ? 'Select an option' : ''}
				options={dropdownOptions}
				errorMessage={error}
				uppercaseHeaderLabel={false}
			/>
			<Box className={classes.errorWrapper}><ErrorMessage errorField={error}/></Box>
		</Box>
	);
}
