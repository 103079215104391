import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import TextLink from '@components/TextLink/TextLink';
import TextLinkProps from '@components/TextLink/TextLinkProps';
import {
	useNavigationHistory
} from '@components/RequestForm/utilities/hooks/useNavigationHistory/useNavigationHistory';

interface BackLinkProps extends Pick<TextLinkProps, 'onClick'> {
	navBackText?: string;
}

const useStyles = makeStyles({
	backBtn: {
		flex: 'auto 0 1'
	}
});

export const BackLink = ({ navBackText, onClick }: BackLinkProps) => {
	const { handleGoBack } = useNavigationHistory();

	const navigateBackText = navBackText || 'Back';
	const classes = useStyles();
	return (
		<Box mt={5} display="inline-block" className={classes.backBtn}>
			<TextLink
				onClick={onClick || handleGoBack}
				prefixIcon={ChevronLeft}
				data-field="navigation-backLink-textLink"
			>
				{navigateBackText}
			</TextLink>
		</Box>
	);
};
