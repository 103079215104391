import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { isEqual } from 'date-fns';
import { FindATimeContextType } from '@components/FindATimeContext/FindATimeContextType';

/**
 * A React hook that clears the selected time slot and closes the accordion in the FindATime component
 * when the appointment location, primary surgeon, procedure date, or procedure name changes.
 *
 * @example
 * useClearSelectionAndCloseAccordion();
 *
 * @returns {void} No return value
 */

interface UseClearSelectionAndCloseAccordion extends Pick<FindATimeContextType, 'setAccordionExpanded' | 'setFindATimeResponse'> {}

export const useClearSelectionAndCloseAccordion = ({ setAccordionExpanded, setFindATimeResponse } : UseClearSelectionAndCloseAccordion): void => {
	// Get the form context
	const { watch } = useFormContext<Partial<CommonRequestForm>>();

	// Watch for changes in appointment location, primary surgeon, procedure date, and procedure name
	const [
		appointmentLocation,
		primarySurgeon,
		procedureDate,
		procedureName,
		duration
	] = watch([
		'appointmentLocation',
		'primarySurgeon',
		'procedureDate',
		'procedureName',
		'duration'
	]);

	// Store the previous values of the watched fields
	const prevValues = React.useRef({
		appointmentLocation,
		primarySurgeon,
		procedureDate,
		procedureName,
		duration
	});

	// Effect to run when any of the watched fields change
	React.useEffect(() => {
		// If any of the watched fields have changed...
		if (
			prevValues.current.appointmentLocation !== appointmentLocation ||
			prevValues.current.primarySurgeon !== primarySurgeon ||
			prevValues.current.procedureDate && procedureDate && !isEqual(prevValues.current.procedureDate, procedureDate) ||
			prevValues.current.procedureName !== procedureName ||
			prevValues.current.duration !== duration
		) {
			// Clear the FindATime response and close the accordion
			setFindATimeResponse(undefined);
			setAccordionExpanded(false);
		}

		// Update the previous values to the current ones
		prevValues.current = { appointmentLocation, primarySurgeon, procedureDate, procedureName, duration };
	}, [
		appointmentLocation,
		primarySurgeon,
		procedureDate,
		procedureName,
		duration,
		setAccordionExpanded,
		setFindATimeResponse,
	]);
};
