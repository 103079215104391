import { AxiosResponse } from 'axios';
import {
	GET_CASE_LIST,
	GET_TASK_COUNT,
	UPDATE_CASE_LIST,
	UPDATE_CASE_LIST_ERROR,
	UPDATE_CASE_LIST_FILTER,
	UPDATE_TASK_COUNT,
} from './types';
import { SAVE_SCROLL_POS } from './types';
import { CaseListTypes, TaskCountInterface } from '@interfaces/CaseListTypes';
import errorToString from '@utilities/errorToString';
import { ApiAction, ApiActionRequest, CaseTrackerThunkAction } from '@interfaces/ApiAction/ApiAction';
import { apiAction } from '../middlewares/api/ApiActionCreators';
import { CaseTrackerListInterface } from '@components/CaseTrackerList/CaseTrackerListInterface';
import { ENDPOINT_SURGERY_SEARCH_API, HEADERS_TOTAL_COUNT } from '@utilities/apiConstants';
import { ApiResponseType } from '@interfaces/ApiAction/ApiActionPayload';
import { FilterApiParams } from '@interfaces/FilterParams';
import { CancelPolicy } from '@interfaces/CancelPolicy';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';

export function saveScrollPos(id: string): CaseListTypes {
	return {
		type: SAVE_SCROLL_POS,
		data: id
	};
}

export function updateCaseList({ data, headers }: AxiosResponse<CaseTrackerListInterface[]>): CaseListTypes {
	return {
		type: UPDATE_CASE_LIST,
		payload: {
			data,
			totalCount: parseInt(headers[HEADERS_TOTAL_COUNT] as string) || 0,
		},
	};
}

export function updateCaseListFilter(filter: FilterApiParams): CaseListTypes {
	return {
		type: UPDATE_CASE_LIST_FILTER,
		payload: filter,
	};
}

export function updateTaskCount({ data }: AxiosResponse<TaskCountInterface>): CaseListTypes {
	return {
		type: UPDATE_TASK_COUNT,
		data: data
	};
}

export function updateCaseListError(error: unknown): CaseListTypes {
	return {
		type: UPDATE_CASE_LIST_ERROR,
		error: errorToString(error)
	};
}

export function getCaseListData(params: FilterApiParams): CaseTrackerThunkAction {
	return (dispatch) => {
		dispatch(updateCaseListFilter(params));
		dispatch(apiAction<unknown, ErrorStatusState, CaseTrackerListInterface[]>({
			url: ENDPOINT_SURGERY_SEARCH_API,
			method: 'POST',
			label: GET_CASE_LIST,
			data: params,
			includeAccessToken: true,
			onSuccess: updateCaseList as unknown as (a: ApiResponseType<CaseTrackerListInterface[]>) => ApiAction,
			onFailure: updateCaseListError,
			cancelPolicy: CancelPolicy.NO_DUPLICATE_PATH,
		}));
	};
}

export function getTaskCount (): ApiActionRequest<TaskCountInterface> {
	return apiAction<TaskCountInterface>({
		url: '/v2/surgery-requests/status/',
		method: 'GET',
		label: GET_TASK_COUNT,
		includeAccessToken: true,
		onSuccess: updateTaskCount as unknown as (a: ApiResponseType<TaskCountInterface>) => ApiAction,
		onFailure: updateCaseListError,
	});
}
