import { FieldValues, useController } from 'react-hook-form';
import React, { ReactElement } from 'react';
import { StandardInputWrapper } from '@components/StatefulInputs/Base/StandardInputWrapper/StandardInputWrapper';
import { OutlinedInput } from '@material-ui/core';
import { StatefulTextInputProps } from '@components/StatefulInputs/StatefulTextInput/StatefulTextInputProps';

export function StatefulTextInput<FormType extends FieldValues>({
	controllerOptions,
	textInputProps,
	...statefulProviderProps
}: StatefulTextInputProps<FormType>): ReactElement {
	const {
		field
	} = useController<FormType>({ name: statefulProviderProps.name, ...controllerOptions });

	return (
		<StandardInputWrapper<FormType>
			{...statefulProviderProps}
			labelProps={{
				id: `textInput-label-${statefulProviderProps.name}`,
				htmlFor: `textInput-field-${statefulProviderProps.name}`,
			}}
		>
			<OutlinedInput
				{...field}
				{...textInputProps}
				name={statefulProviderProps.name}
				id={`textInput-field-${statefulProviderProps.name}`}
				value={textInputProps?.value || field.value || controllerOptions.defaultValue || ''}
			/>
		</StandardInputWrapper>
	);
}
