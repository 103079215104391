import React from 'react';
import {
	Box,
	Button,
	Flex,
	Icon,
	Text,
} from '@ascension/web';
import { useHistory } from 'react-router-dom';
import usePageEffects from '../../utilities/hooks/usePageEffects/usePageEffects';

export const ConfirmSetup = () => {
	const history = useHistory();
	usePageEffects('Confirm Setup');
	return (
		<Flex
			minHeight="calc(100vh - 80px)"
			height="100%"
			width="100%"
			justifyContent="center"
			pt={[10, 40]}
		>
			<Flex
				height="90%"
				width="100%"
				className="card"
				data-section="main"
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				p={40}
				maxHeight="500px"
				maxWidth="800px"
			>
				<Flex
					flexDirection="column"
					position="relative"
					alignItems="center"
					justifyContent="center"
					data-field="icon"
				>
					<Box
						backgroundColor="#01a890"
						height="120px"
						width="120px"
						borderRadius="50%"
						position="relative"
						zIndex={0}
					/>
					<Box
						zIndex={1}
						position="absolute"
					>
						<Icon
							name="check"
							size={50}
							color="white"
						/>
					</Box>
				</Flex>
				<Text
					pt={10}
					fontSize={34}
					fontWeight={700}
					data-field="title"
				>
					Setup Finished
				</Text>
				<Text
					p={20}
					fontSize={20}
					textAlign="center"
					maxWidth="500px"
					data-field="help text"
				>
					Remember, you can always update your information in account settings.
				</Text>
				<Button onClick={() => history.push('/')}>
					Go to Home Page
				</Button>
			</Flex>
		</Flex>
	);
};
