import React from 'react';
import { PRIMARY_PROCEDURE_CHANGED_MODAL } from '@components/Modal/modalConstants';
import {
	CUSTOM_PROCEDURE_OPTION,
	useProcedureNameContext
} from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { useAnchorContext } from '@components/AnchorProvider/AnchorProvider';
import { useFormContext } from 'react-hook-form';
import { WebRequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useModalContext } from '@components/Modal/ModalContextProvider';

/**
 * `useChangePrimaryProcedureName` is a custom hook that handles the change of the primary procedure name.
 *
 * @returns A callback function that takes a string value as an argument.
 * This function performs the following:
 * - Checks if a primary procedure is already selected and if the new value is different.
 * - If the conditions are met, it sets the previous procedure name and opens a modal.
 * - Regardless of the conditions, it sets the selected procedure name and clears the anchor element.
 *
 * @example
 * const changePrimaryProcedureName = useChangePrimaryProcedureName();
 * changePrimaryProcedureName('New Procedure Name');
 */

export const useChangePrimaryProcedureName = () => {
	const {
		selectedProcedure,
		setProcedureFilter,
		setSelectedProcedureName,
		isCustomProcedure,
		isProcedureOption
	} = useProcedureNameContext();
	const { handleClearAnchorEl } = useAnchorContext();
	const { getValues, setValue } = useFormContext<WebRequestFormValidatedState>();
	const { openModal } = useModalContext();

	return React.useCallback((value: string) => {
		const { surgeryProcedures = [] } = getValues();
		if (
			surgeryProcedures.some(proc => proc.isPrimaryProcedure) &&
			value !== selectedProcedure?.procedureName
		) {
			openModal(PRIMARY_PROCEDURE_CHANGED_MODAL.id);
		}
		handleClearAnchorEl();
		setProcedureFilter('');

		if (isCustomProcedure && value === CUSTOM_PROCEDURE_OPTION) {
			return;
		}

		if (isCustomProcedure && !isProcedureOption(value) && value !== CUSTOM_PROCEDURE_OPTION) {
			setValue('procedureName', value);
			return;
		}

		setSelectedProcedureName(value);
	}, [getValues, handleClearAnchorEl, isCustomProcedure, isProcedureOption, openModal, selectedProcedure?.procedureName, setProcedureFilter, setSelectedProcedureName, setValue]);
};
