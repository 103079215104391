import React, { useContext } from 'react';
import SchedulePageContextType from '@components/SchedulePage/SchedulePageContext/SchedulePageContextType';
import useGetParsedSchedules from '@components/SchedulePage/utilities/useGetParsedSchedules/useGetParsedSchedules';
import { addBusinessDays, format } from 'date-fns';
import useSurgeonsSelectionByNpis
	from '@components/SchedulePage/utilities/useSurgeonsSelectionByNpis/useSurgeonsSelectionByNpis';
import { StorageKey } from '@utilities/authConstants';
import useSessionStorageCache from '@utilities/hooks/useSessionStorageCache/useSessionStorageCache';
import { useFacilitySelection } from '@components/SchedulePage/utilities/useFacilitySelection/useFacilitySelection';

const SchedulePageContext = React.createContext<null | SchedulePageContextType>(null);

export const useSchedulePageContext = () => {
	const ctx = useContext(SchedulePageContext);
	if (!ctx) {
		throw new Error('useSchedulePageContext must be used within a child component of SchedulePageProvider');
	}
	return ctx;
};

export const twoBusinessDaysFromToday = addBusinessDays(new Date(), 2);

export const SchedulePageProvider: React.FC = ({ children }) => {
	const {
		selectedFacility,
		setSelectedFacility,
		facilitiesSorted,
	} = useFacilitySelection();

	const {
		prefSurgeons,
		selectedSurgeonsByNpis,
		setSelectedSurgeonsByNpis,
	} = useSurgeonsSelectionByNpis();

	const {
		apiError,
		schedules,
		getApiBlocksAndProcessForUi,
		lastUpdate
	} = useGetParsedSchedules();

	const {
		storedValue: storedDate,
		setStoredValue: setStoredDate
	} = useSessionStorageCache(StorageKey.BLOCK_DATE);

	const defaultSelectedDate = React.useMemo(() =>
		storedDate ? new Date(storedDate) : twoBusinessDaysFromToday
	, [storedDate]);

	const [selectedDate, setSelectedDate] = React.useState(defaultSelectedDate);

	React.useEffect(() => {
		setStoredDate(format(selectedDate, 'yyyy-MM-dd') + ' 00:00');
	}, [selectedDate, setStoredDate]);

	const getSchedules = React.useCallback(() => {
		selectedSurgeonsByNpis?.length && selectedFacility && getApiBlocksAndProcessForUi(selectedSurgeonsByNpis, selectedFacility, selectedDate);
	}, [getApiBlocksAndProcessForUi, selectedDate, selectedFacility, selectedSurgeonsByNpis]);

	React.useEffect(() => {
		getSchedules();
	}, [getSchedules]);

	return (
		<SchedulePageContext.Provider value={{
			apiError,
			schedules,
			getSchedules: getSchedules,
			lastUpdate,
			prefSurgeons,
			selectedSurgeonsByNpis,
			setSelectedSurgeonsByNpis,
			selectedDate,
			setSelectedDate,
			selectedFacility,
			setSelectedFacility,
			facilitiesSorted
		}}>
			{children}
		</SchedulePageContext.Provider>
	);
};
