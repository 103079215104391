import React, { FormEvent } from 'react';
import ModalViewer from '@components/Modal/ModalViewer';
import { COPY_CASE_MODAL } from '@components/Modal/modalConstants';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, Theme, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useCopyCaseState } from '@components/CaseDetails/CopyCaseContext/CopyCaseContext';
import { FormSection } from '@components/RequestForm/interface/FormSection';
import { Button } from '@ascension/ui-library';
import { useModalContext } from '@components/Modal/ModalContextProvider';

const useStyles = makeStyles((theme: Theme) => ({
	mainContainer: {
		paddingBottom: theme.spacing(2),
		width: '400px',
	},
	titleSection: {
		paddingBottom: theme.spacing(3),
	},
	topContentSection: {
		color: theme.palette.grey[700],
		fontWeight: 600,
		paddingBottom: theme.spacing(1),
	},
	checkboxContainer: {
		fontFamily: theme.typography.fontFamily,
		fontSize: '18px',
	},
	checkBoxStyle: {
		paddingLeft: theme.spacing(2),
	},
	buttonsWrapperStyle: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingTop: theme.spacing(1),
	},
	copyButtonStyle: {
		'&.Mui-disabled': {
			color: '#CCC',
			backgroundColor: '#666666'
		},
		'backgroundColor': theme.palette.primary.main,
		'marginLeft': theme.spacing(2),
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
}));

export const SchedulingCopyCaseModal = () => {
	const history = useHistory();
	const { sectionOptions, toggleSection, isAllChecked, toggleAllOption } = useCopyCaseState();
	const { closeModal } = useModalContext();
	const handleCloseModal = () => {
		closeModal(COPY_CASE_MODAL);
	};

	const copyContentSelections = React.useMemo(() => {
		const options = Object.entries(sectionOptions).map(([key, value]) => ({ label: key, selected: value }));
		const selections: string[] = options.filter(opt => opt.selected).map(sel => sel.label);
		if (selections.length === options.length) {
			selections.unshift('all');
		}

		return selections;
	}, [sectionOptions]);

	const copyClickHandler = (ev: FormEvent<HTMLFormElement>) => {
		ev.preventDefault();
		handleCloseModal();
		history.push('/request', {
			copyFrom: {
				sections: copyContentSelections,
			}
		});
	};
	const disableCopyBtn = React.useMemo(() =>
		// If any of the options are true, then do not disable. All can be implied false when any are true
		Object.values(sectionOptions)
			.every(option => !option), [sectionOptions]);

	const classes = useStyles();

	const getToggleHandler = React.useCallback((forSection: FormSection) => {
		return () => toggleSection(forSection);
	}, [toggleSection]);

	return (
		<ModalViewer id={COPY_CASE_MODAL}>
			<form onSubmit={copyClickHandler}>
				<Box className={classes.mainContainer}>
					<Typography className={classes.titleSection} variant="h2">
						Copy Case
					</Typography>
					<Typography className={classes.topContentSection}>
						What information do you want to copy?
					</Typography>
					<Box className={classes.checkboxContainer}>
						<Checkbox
							id="checkbox_all"
							data-field="schedulePage-copyModal-allCheckbox"
							checked={isAllChecked}
							onChange={toggleAllOption}
							color="primary"
							className={classes.checkBoxStyle}
						/>
						All
					</Box>
					<Box className={classes.checkboxContainer}>
						<Checkbox
							id="checkbox_patient"
							data-field="schedulePage-copyModal-patientCheckbox"
							checked={sectionOptions.patient}
							onChange={getToggleHandler(FormSection.patient)}
							color="primary"
							className={classes.checkBoxStyle}
						/>
						Patient Demographics
					</Box>
					<Box className={classes.checkboxContainer}>
						<Checkbox
							id="checkbox_insurance"
							data-field="schedulePage-copyModal-insuranceCheckbox"
							checked={sectionOptions.insurance}
							onChange={getToggleHandler(FormSection.insurance)}
							color="primary"
							className={classes.checkBoxStyle}
						/>
						Insurance Information
					</Box>
				</Box>
				<Box className={classes.buttonsWrapperStyle}>
					<Button
						translate="yes"
						color="primary"
						variant="outlined"
						onClick={handleCloseModal}
						data-field="schedulePage-copyModal-cancelButton"
					>
						Cancel
					</Button>
					<Button
						className={classes.copyButtonStyle}
						translate="yes"
						color="white"
						disabled={disableCopyBtn}
						data-field="schedulePage-copyModal-confirmButton"
						type="submit"
					>
						Copy Case
					</Button>
				</Box>
			</form>
		</ModalViewer>
	);
};
