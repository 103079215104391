import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
	AppointmentDetailsValidationSchema
} from '@components/RequestForm/AppointmentDetails/AppointmentDetailsValidationSchema';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { SelectOption } from '@components/StatefulInputs/StatefulSelect/StatefulSelectProps';
import { StatefulSelect } from '@components/StatefulInputs/StatefulSelect/StatefulSelect';
import { useChangeSurgeryLocation } from '@components/RequestForm/utilities/hooks/useChangeSurgeryLocation/useChangeSurgeryLocation';
import { SURGERY_LOCATION_CHANGED_MODAL } from '@components/Modal/modalConstants';
import {
	RequestFormValidatedState,
} from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useMultiFormValueSetter } from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapter';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import {
	CriticalCriteriaChangedModal
} from '@components/RequestForm/CriticalCriteriaChangedModal/CriticalCriteriaChangedModal';
import { doesUnitHaveOpenTime } from '@utilities/doesUnitHaveOpenTime';

const FIELD_NAME: keyof CommonRequestForm = 'appointmentLocation';

export const SurgeryLocation: React.FC = () => {
	const units = useCollection<Unit>(ENDPOINT_USER_UNITS);
	const setSelectedUnitId = useMultiFormValueSetter(FIELD_NAME);
	const { closeModal } = useModalContext();
	const {
		setSelectedProcedureName,
		previousProcedureName,
		isCustomProcedure,
		setCustomProcedure
	} = useProcedureNameContext();

	const { getValues, setValue, formState } = useFormContext<RequestFormValidatedState>();
	const defaultProcedureName = formState.defaultValues?.procedureName;

	const handleChangeSurgeryLocation = useChangeSurgeryLocation();
	const [newAppointmentLocation, setNewAppointmentLocation] = React.useState('');

	const handleOnBeforeChange = React.useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
		setNewAppointmentLocation(e.target.value as string);
		return handleChangeSurgeryLocation(e.target.value as string);
	}, [handleChangeSurgeryLocation, setNewAppointmentLocation]);

	const handleKeepPrevious = React.useCallback(() => {
		if (defaultProcedureName && !previousProcedureName.current) {
			if (!isCustomProcedure) {
				setSelectedProcedureName(defaultProcedureName);
			} else {
				setCustomProcedure(defaultProcedureName);
			}
		} else {
			setSelectedProcedureName(previousProcedureName.current);
		}
		closeModal(SURGERY_LOCATION_CHANGED_MODAL.id);
	}, [closeModal, defaultProcedureName, isCustomProcedure, previousProcedureName, setCustomProcedure, setSelectedProcedureName]);

	const handleAcceptChange = React.useCallback(() => {
		setSelectedUnitId(newAppointmentLocation);
		setSelectedProcedureName('');
		const nonPrimaryProcedures = getValues('surgeryProcedures').filter(s => !s.isPrimaryProcedure);
		setValue('surgeryProcedures', nonPrimaryProcedures);
		setValue('procedureName', '');

		const newUnit = units.find(({ id }) => id === newAppointmentLocation);
		if (newUnit && doesUnitHaveOpenTime(newUnit)) {
			setValue('duration', '');
		}
		closeModal(SURGERY_LOCATION_CHANGED_MODAL.id);
	}, [setSelectedUnitId, newAppointmentLocation, setSelectedProcedureName, getValues, setValue, units, closeModal]);

	const options: SelectOption[] = React.useMemo(() => {
		return units
			.map(({ id, hospitalName, name }) => ({
				value: id,
				label: `${hospitalName}, ${name}`,
			}))
			.sort(({ label: aLabel }, { label: bLabel }) => aLabel.localeCompare(bLabel));
	}, [units]);

	return (
		<>
			<StatefulSelect<CommonRequestForm>
				controllerOptions={{
					rules: {
						required: AppointmentDetailsValidationSchema.appointmentLocation.required,
					},
				}}
				onBeforeChange={handleOnBeforeChange}
				label="Surgery location"
				name="appointmentLocation"
				options={options}
				styleAsRequired={true}
			/>

			<CriticalCriteriaChangedModal
				modalValues={SURGERY_LOCATION_CHANGED_MODAL}
				onClose={handleKeepPrevious}
				onAcceptChange={handleAcceptChange}
				onKeepPrevious={handleKeepPrevious}
			/>
		</>);
};
