import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RequestFormProcedureNameSelect } from '@components/RequestForm/inputs/RequestFormProcedureName/RequestFormProcedureNameSelect/RequestFormProcedureNameSelect';
import {
	CriticalCriteriaChangedModal
} from '@components/RequestForm/CriticalCriteriaChangedModal/CriticalCriteriaChangedModal';
import {
	useProcedureNameContext
} from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import {
	WebRequestFormValidatedState
} from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { PRIMARY_PROCEDURE_CHANGED_MODAL } from '@components/Modal/modalConstants';

export const RequestFormProcedureName = () => {
	const {
		setSelectedProcedureName,
		isCustomProcedure,
		previousProcedureName,
		isProcedureOption,
		setCustomProcedure,
		triggerProcedureNameValidation
	} = useProcedureNameContext();
	const { getValues, formState, setValue } = useFormContext<WebRequestFormValidatedState>();
	const defaultProcedureName = formState.defaultValues?.procedureName;
	const { closeModal } = useModalContext();

	const handleKeepPrevious = React.useCallback(() => {
		closeModal(PRIMARY_PROCEDURE_CHANGED_MODAL.id);

		// In edit mode, when there is a default procedure name and the previous procedure name is not set
		if (defaultProcedureName && !previousProcedureName.current) {
			// When made a change, check if the default procedure name is a procedure option
			// If it is, set the selected procedure name in dropdown, otherwise set the custom procedure name
			const procedureSetter = isProcedureOption(defaultProcedureName) ? setSelectedProcedureName : setCustomProcedure;
			procedureSetter(defaultProcedureName);
			return;
		}

		// When the previous procedure name is set
		if (previousProcedureName.current) {
			if (isCustomProcedure) {
				// When it is a custom procedure, check if there is a procedure name in the input field
				const procedureName = getValues('procedureName');
				// If yes, set the custom procedure name, otherwise set the selected procedure name in dropdown
				procedureName ? setCustomProcedure(previousProcedureName.current) : setSelectedProcedureName(previousProcedureName.current);
			}
			// If it was a custom procedure with a custom value but the user changed it to a procedure option
			// Make it a custom procedure and set the previous value, otherwise set the selected procedure name in dropdown
			else {
				!isProcedureOption(previousProcedureName.current)
					? setCustomProcedure(previousProcedureName.current)
					: setSelectedProcedureName(previousProcedureName.current);
			}
		}

		triggerProcedureNameValidation();
	}, [closeModal, defaultProcedureName, getValues, isCustomProcedure, isProcedureOption, previousProcedureName, setCustomProcedure, setSelectedProcedureName, triggerProcedureNameValidation]);

	const handleAcceptChange = React.useCallback(() => {
		const { surgeryProcedures } = getValues();
		setValue('surgeryProcedures', surgeryProcedures.filter(proc => !proc.isPrimaryProcedure));
		closeModal(PRIMARY_PROCEDURE_CHANGED_MODAL.id);
	}, [closeModal, getValues, setValue]);

	return (
		<>
			<RequestFormProcedureNameSelect />
			<CriticalCriteriaChangedModal
				modalValues={PRIMARY_PROCEDURE_CHANGED_MODAL}
				onClose={handleKeepPrevious}
				onAcceptChange={handleAcceptChange}
				onKeepPrevious={handleKeepPrevious}
			/>
		</>
	);
};
