import React, { ReactElement } from 'react';
import { StatefulSelectProps } from '@components/StatefulInputs/StatefulSelect/StatefulSelectProps';
import { MenuItem, Select } from '@material-ui/core';
import { StandardInputWrapper } from '@components/StatefulInputs/Base/StandardInputWrapper/StandardInputWrapper';
import { FieldValues, useController } from 'react-hook-form';

/**
 * `onBeforeChange` is an optional callback function that is invoked before the state of the select input is changed.
 * This function receives the change event as its argument.
 *
 * The function should return a boolean value:
 * - If it returns `true`, the select input's state will be updated with the new value.
 * - If it returns `false`, the select input's state will not be updated, effectively preventing the change.
 *
 * This can be useful when you want to perform validation or other checks before allowing the state to change.
 *
 * @example
 * <StatefulSelect
 *   onBeforeChange={(event) => {
 *     // Prevent the state from changing if the new value is 'invalid'
 *     if (event.target.value === 'invalid') {
 *       return false;
 *     }
 *     return true;
 *   }}
 *   // other props...
 * />
 **/

export function StatefulSelect<FormType extends FieldValues>({
	options,
	controllerOptions,
	onBeforeChange,
	...statefulProviderProps
}: StatefulSelectProps<FormType>): ReactElement {
	const {
		field
	} = useController<FormType>({ name: statefulProviderProps.name, ...controllerOptions });

	const handleChange = React.useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
		if (!onBeforeChange || onBeforeChange(event)) {
			field.onChange(event);
		}
	}, [field, onBeforeChange]);

	return (
		<StandardInputWrapper<FormType>
			{...statefulProviderProps}
			labelProps={{
				id: `select-label-${statefulProviderProps.name}`,
				htmlFor: `select-field-${statefulProviderProps.name}`,
			}}
			analyticsInputName="dropDown"
		>
			<Select
				{...field}
				name={statefulProviderProps.name}
				id={`select-field-${statefulProviderProps.name}`}
				labelId={`select-label-${statefulProviderProps.name}`}
				value={field.value || controllerOptions.defaultValue || ''}
				onChange={handleChange}
			>
				{options.map(({ value, label }) => (
					<MenuItem key={value} value={value}>
						{label}
					</MenuItem>
				))}
			</Select>
		</StandardInputWrapper>
	);
}
