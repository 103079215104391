//COMMON
export const INVALID_DATE_FORMAT = 'Please enter date in MM/DD/YYYY format.';
export const INVALID_DATE = 'Please enter a valid date.';
export const INVALID_PAST_DATE = 'Please enter a date in the past.';
export const INVALID_FUTURE_DATE = 'Please enter a date in the future.';
export const INVALID_TIME = 'Please enter a valid time.';
export const INVALID_NUMBER = 'Please enter a valid number.';

//PATIENT DETAILS
export const INVALID_SSN_LONG = 'Patient SSN requires the last 4 digits, or the full 9 digit SSN.';
export const INVALID_SSN = 'Please enter a valid SSN';
export const INVALID_GENDER = 'Please enter a valid gender';
export const INVALID_FIRST_NAME = 'Please enter a valid first name.';
export const INVALID_MIDDLE_NAME = 'Please enter a valid middle name.';
export const INVALID_LAST_NAME = 'Please enter a valid last name.';
export const INVALID_EMAIL = 'Please enter a valid email address';
export const INVALID_PHONE_NUMBER = 'Please enter a valid phone number.';
export const INVALID_EXTENSION = 'Please enter a valid extension.';
export const INVALID_LANGUAGE = 'Please enter a valid language';
export const REQUIRED_DROPDOWN_NO_OPTION_SELECTED = 'Please select an option.';
export const REQUIRED_ADDRESS_1 = 'Address 1 is required.';
export const REQUIRED_CITY = 'City is required.';
export const REQUIRED_STATE = 'State selection is required.';
export const INVALID_ZIP_CODE = 'Invalid zip. 5-digit zip code required.';

//SURGERY
export const ADDITIONAL_COMMENTS_LENGTH_EXCEEDED = 'Additional comments should be less than 1000 characters.';
export const OTHER_EQUIPMENT_LENGTH_EXCEEDED = 'Other equipment and supplies should be less than 1000 characters.';
export const PROCEDURE_LENGTH_EXCEEDED = 'Procedure name should be less than 1000 characters.';
export const INVALID_PROCEDURE_NAME = 'Please enter a procedure name.';
export const INVALID_PROCEDURE_DURATION = 'Please enter duration in minutes.';
export const PROCEDURE_DURATION_MINIMUM = 'Please enter at least 45 minutes.';
export const INVALID_PRIMARY_PROCEDURE = 'Please select a primary procedure.';
export const SURGEON_NOT_SELECTED = 'Please select a surgeon.';
export const INVALID_CPT_CODE = 'Please enter a valid CPT code.';
export const ANESTHESIA_TYPE_NOT_SELECTED = 'Please select an anesthesia type.';
export const IMPLANTS_NEEDED_NOT_SELECTED = 'Please select an option.';
export const VENDOR_CONTACTED_NOT_SELECTED = 'Please select an option.';
export const INVALID_PRIMARY_DIAGNOSIS_CODE = 'Please enter a valid code.';
export const PROCEDURE_NAME_MISSING = 'Please enter a procedure.';
export const PROCEDURE_NO_PRIMARY_ERROR = 'One procedure must be entered as primary.';
export const PROCEDURE_MULTIPLE_PRIMARY_ERROR = 'Only one procedure may be entered as primary.';
export const PRIMARY_SURGEON_NOT_SELECTED = 'Primary surgeon is required.';
export const PRIMARY_SURGEON_MISMATCH = 'Primary surgeon mismatched from primary procedure.';
export const PRIMARY_PROCEDURE_NAME_NOT_SELECTED = 'This field is required. Please select.';
export const CUSTOM_PROCEDURE_NAME_REQUIRED = 'Please enter a valid procedure name.';

//PRE-ADMIT
export const PAT_LOCATION_MISSING = 'Please enter a location.';

//INSURANCE
export const INVALID_HEALTH_PLAN_NAME = 'Please enter a valid health plan name.';
export const INVALID_POLICY_NUMBER = 'Please enter a valid Policy number.';
export const INVALID_GROUP_NUMBER = 'Please enter a valid Group number.';
export const PRE_AUTH_OPTION_NOT_SELECTED = 'Please select an option for PRE-AUTH.';
export const INVALID_PRE_CERT_NUMBER = 'Please enter a valid Pre-Cert number.';

//TEMPLATES
export const TEMPLATE_NAME_LENGTH_EXCEEDED = 'Template name should be Less than 1000 Characters';
export const TEMPLATE_NAME_MISSING = 'Please enter a template name.';

//REVISION
export const INVALID_REVISION_MESSAGE_FOR_OR = 'Please enter a valid message.';
export const INVALID_REVISION_MESSAGE_FOR_OFFICE = 'Please enter a valid revision message.';
export const REVISION_INIT_BY_NOT_SELECTED = 'Revision Initiated by not selected';
export const REVISION_MESSAGE_MISSING = 'Please enter revision message';
export const REVISION_REASON_MISSING = 'Please select a revision reason.';
export const REVISION_MESSAGE_LENGTH_EXCEEDED = 'Revision message character exceeding max length of 500';
export const REVISION_CHECKBOXES_NONE_SELECTED = 'Please Select an option.';

//REGISTRATION
export const INVALID_MONTH_OR_DAY_OF_BIRTH = 'Please enter a month and day of birth';
export const INVALID_LAST_FOUR_SSN = 'Please enter user\'s last 4 digits of social security number';
export const ACCOUNT_INFORMATION_CHECKBOXES_NONE_SELECTED = 'Please select an option';
export const INVALID_ASCENSION_USERNAME = 'Please enter user\'s Ascension username';

//UNAVAILABLE
export const REQUIRED_DATE = 'Please enter a valid date';
export const REQUIRED_START_TIME = 'Please enter a start time';
export const REQUIRED_END_TIME = 'Please enter an end time';
export const INVALID_START_TIME = 'Please enter a valid start time';
export const INVALID_END_TIME = 'Please enter a valid end time';
export const INVALID_TIME_INCREMENTS = 'Time must be entered in {increment} minute increments';
export const INVALID_TIME_SPAN = 'End time must be later than the start time';
export const OVERLAPPING_UNAVAILABLE_TIME = 'Time is overlapping another Unavailable Time event';
export const OVERLAPPING_SCHEDULED_TIME = 'Unavailable time is overlapping a scheduled case';
export const HOLD_EVENT_OVERLAPPING_TIME = 'Selected time is not available';

// DOCUMENTS
export const DOC_REQUIRED = 'At least one document is required to submit the request.';

// INDEX DOCUMENTS
export const PAGES_INDEX_REQUIRED = 'Invalid';
export const DOCUMENT_TYPE_REQUIRED = 'Document type is required';
