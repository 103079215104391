import React, { useCallback } from 'react';
import { Box, Flex, Text, TextLink } from '@ascension/web';
import { useDispatch, useSelector } from 'react-redux';
import { getCaseListData } from '@store/actions/CaseListActionCreators';
import { RootState } from '@interfaces/rootState';
import CaseCard from '@components/CaseTrackerList/CaseCard/CaseCard';
import { CaseTrackerSection } from '@components/CaseTrackerSection/CaseTrackerSection';
import SortingComponent from '@components/SortingComponent/SortingComponent';
import { useHistory, useLocation } from 'react-router-dom';
import ProcedureDateFilter from '@components/Filters/ProcedureDate/ProcedureDateFilter';
import CaseListOtherFilters from '@components/Filters/CaseListOtherFilters/CaseListOtherFilters';
import { PreferredSurgeonFilter } from '@components/Filters/SurgeonFilter/SurgeonFilter';
import ListHeaderComponent from '@components/ListHeader/ListHeaderComponent';
import { DEFAULT_CASES_SORT } from '@utilities/apiConstants';
import { CaseTrackerListInterface } from '@components/CaseTrackerList/CaseTrackerListInterface';
import PaginationComponent from '@components/Pagination/PaginationComponent';
import TaskSection from '@components/CaseTrackerList/TaskSection/TaskSection';
import {
	FiltersProvider,
	useResetAllFilters,
	useFilterState,
} from '@components/Filters/FiltersContext/FiltersContext';
import { FilterApiParams } from '@interfaces/FilterParams';
import usePageEffects from '@utilities/hooks/usePageEffects/usePageEffects';
import { CaseListCountSelector, CaseListFilterSelector } from '@store/selectors/CaseListSelector';
import { makeStyles } from '@material-ui/core/styles';
import { filtersToReplaceString } from '@utilities/commonFunction';
import { CASES_ENTITY_NAME, filterSortOptions, QUEUE_BASE_ROUTE } from '@utilities/constants';
import SavedFilterSwitch from '@components/SavedFilterSwitch/SavedFilterSwitch';
import { MyFiltersSelector } from '@store/selectors/MyFiltersSelector';
import { savedFiltersToApiParams } from '@utilities/filtersUtilities';
import { MyFiltersState } from '@interfaces/MyFilters/MyFiltersState';
import { UserProfileFilterUrlSelector, UserProfileHasARoleSelector } from '@store/selectors/UserProfileSelector';
import { OfficeRoles } from '@interfaces/UserProfile/UserRoleEnum';
import { StorageKey } from '@utilities/authConstants';
import { saveScrollPos } from '@store/actions/CaseListActionCreators';
import { useAppPageBodyClassSetter } from '@components/AppPage/AppPageContext/AppPageContext';
import { maxWidthContainerStyle } from '@theme/useMaxWidthContainerStyle';
import useSurgeonsFilterUpdate from '@utilities/hooks/useSurgeonsFilterUpdate/useSurgeonsFilterUpdate';
import getInitialFilters from '@components/CaseTrackerList/utilities/getInitialFilters/getInitialFilters';
import {
	useHomeNavigationContext
} from '@components/HeaderSection/NavigationOptions/HomeNavigationContext/HomeNavigationContext';

const useStyles = makeStyles(theme => ({
	listHeader: {
		fontSize: '14px',
		borderTop: '1px solid #C9C9C9',
	},
	filterSwitchText: {
		alignSelf: 'center'
	},
	appBody: {
		'backgroundColor': theme.palette.common.white,
		'height': 'calc(100vh - 136px)',
		'overflow': 'auto',
		'& > div': maxWidthContainerStyle(),
	}
}));

const ListEmpty = () => {
	const savedFilters = useSelector(MyFiltersSelector);
	const { filterApplied, toggleFilter } = useFilterState();
	const resetFilters = useResetAllFilters();
	const isOfficeRole = useSelector(UserProfileHasARoleSelector(OfficeRoles));
	const savedFilterUrl = useSelector(UserProfileFilterUrlSelector);
	const { updateSurgeonsWithIds } = useSurgeonsFilterUpdate();

	const handleResetFilters = useCallback(() => {
		const savedFiltersByRole: MyFiltersState = isOfficeRole ?
			{ ...savedFilters, surgeon: [] } :
			{ ...savedFilters, unit: [] };
		updateSurgeonsWithIds([]);
		!!savedFilterUrl && toggleFilter(true);
		resetFilters(savedFiltersToApiParams(savedFiltersByRole));
	}, [isOfficeRole, resetFilters, savedFilterUrl, savedFilters, toggleFilter, updateSurgeonsWithIds]);

	return (
		<Flex mt={5} justifyContent="center" alignItems="center" flexDirection="column" data-field="Reset_Filters">
			<Text fontSize={12} color="#999" lineHeight={1.25} display="block">Try editing your selected filters to see more cases, or reset your filters to see all cases. </Text>
			{!filterApplied && <TextLink mt={5} onMouseDown={handleResetFilters} id="resetFilters">CLEAR FILTERS</TextLink>}
		</Flex>
	);
};

const ListInner = () => {
	const dispatch = useDispatch();
	const updateScrollPos = useCallback((id: string) => {
		dispatch(saveScrollPos(id));
	}, [dispatch]);
	const classes = useStyles();
	const { updateSurgeonsWithIds } = useSurgeonsFilterUpdate();

	const { setBodyClass } = useAppPageBodyClassSetter('CaseList');

	React.useEffect(() => {
		setBodyClass(classes.appBody);
	}, [classes.appBody, setBodyClass]);

	const { caseList: caseDetails, error, scrollPos } = useSelector((state: RootState) => state.caseList);

	usePageEffects('Home Page');
	const history = useHistory();

	const { filters } = useFilterState();
	const filtersString = filtersToReplaceString(filters, '');
	const pushAndSetItem = (link: string) => {
		sessionStorage.setItem(StorageKey.QUEUE_QUERY, link);
		history.push(QUEUE_BASE_ROUTE);
	};
	const scrollTo = document.getElementById(scrollPos);
	if (scrollTo) {
		scrollTo.scrollIntoView({ block: 'center', behavior: 'smooth' });
	}

	return (
		<Box>
			<Flex pt={40} mb={18} justifyContent="center" alignItems="center">
				<TaskSection/>
			</Flex>
			<CaseTrackerSection sectionTitle="Cases" width={1}>
				<Flex width={1} justifyContent="stretch" className="container standard-gap" minHeight={80}>
					<Box flex="2 2 17%" pr="10px">
						<SortingComponent options={filterSortOptions}/>
					</Box>
					<Box flex="4 4 33%" pr="10px">
						<PreferredSurgeonFilter onSurgeonFilterUpdate={updateSurgeonsWithIds} />
					</Box>
					<Box flex="3 3 25%" pr="10px">
						<CaseListOtherFilters />
					</Box>
					<Box flex="3 3 25%">
						<ProcedureDateFilter />
					</Box>
				</Flex>
				<Flex>
					<SavedFilterSwitch/>
					<Text className={classes.filterSwitchText}>My Filters</Text>
				</Flex>
			</CaseTrackerSection>
			{(caseDetails.length > 0) &&
				<Box data-field="CaseListHeader" pt={10} >
					<ListHeaderComponent className={classes.listHeader}>
						<TextLink mt={1} data-field="reviewCasesPage" onClick={() => pushAndSetItem(filtersString)}>
							REVIEW SEARCHED CASES &nbsp; <span className="angle-right">{'>'}</span>
						</TextLink>
					</ListHeaderComponent>
				</Box>
			}
			{error || caseDetails.map((item: CaseTrackerListInterface, index: number) => (
				<Box data-field={'caseCardDetails' + item.id} key={index} id={item.id}>
					<CaseCard caseDetails={item}  onClick={() => {
						updateScrollPos(item.id);
						history.push('/cases/' + item.id);
					}}/>
				</Box>
			))}
			{(caseDetails.length > 0) &&
				<Box data-field="Pagination" pt={20} pb={30} >
					<PaginationComponent displayPerPage={true} fontSize="14px"/>
				</Box>
			}
			{(caseDetails.length == 0) &&
				<>
					<Flex mt={11} justifyContent="center" alignItems="center">
						<Text fontSize={18} display="block" white-space="pre-line">Sorry, this search didn&apos;t produce any results.</Text>
					</Flex>
					<ListEmpty />
				</>
			}
		</Box>
	);
};

interface LocationState {
	shouldReloadApp: boolean;
}

export default function CaseTrackerList() {
	const dispatch = useDispatch();
	const currentFilters = useSelector(CaseListFilterSelector);
	const { filterMappedIdList } = useSurgeonsFilterUpdate();
	const initialFilterRef = React.useRef<FilterApiParams | undefined>(getInitialFilters(currentFilters, filterMappedIdList));
	const [shouldDisableDefaultFilters] = React.useState(!!filterMappedIdList);
	const { state } = useLocation<LocationState>();
	const { setHomeRoute } = useHomeNavigationContext();

	React.useEffect(() => {
		if (state && state.shouldReloadApp) {
			window.location.reload();
		}
	}, [state]);

	const loadListHandler = useCallback((urlParams: FilterApiParams) => {
		dispatch(getCaseListData(urlParams));
	}, [dispatch]);

	React.useEffect(() => {
		setHomeRoute('/caselist');
	}, [setHomeRoute]);

	return (
		<FiltersProvider
			entityName={CASES_ENTITY_NAME}
			totalCountSelector={CaseListCountSelector}
			defaultSort={DEFAULT_CASES_SORT}
			initialFilterRef={initialFilterRef}
			onListLoadRequest={loadListHandler}
			disableDefaultFilters={shouldDisableDefaultFilters}
		>
			<ListInner />
		</FiltersProvider>
	);
}
