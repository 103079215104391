import React from 'react';
import { MenuItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { SelectOption } from '@components/StatefulInputs/StatefulSelect/StatefulSelectProps';
import BoxFancyScroll from '@components/BoxFancyScroll/BoxFancyScroll';

const useStyles = makeStyles((theme) => createStyles({
	options: {
		flexDirection: 'column',
		flex: '1 1 auto',
		overflowY: 'scroll',
		gap: theme.spacing(1),
	},
	optionItem: {
		lineHeight: '24px',
		fontSize: '18px',
		fontWeight: 400,
		padding: theme.spacing(1, 0, 1, 2),
	}
}));

interface ProcedureNameOptionsProps {
	onClick: (value: string) => void;
	selectedIndex: number;
}

export const ProcedureNameOptions: React.FC<ProcedureNameOptionsProps> = ({ onClick, selectedIndex }) => {
	const classes = useStyles();
	const { procedures } = useProcedureNameContext();
	const selectedRef = React.useRef<HTMLLIElement>(null);

	const options: SelectOption[] = React.useMemo(() => {
		return procedures
			.map(procedure => {
				return {
					value: procedure.procedureName,
					label: procedure.procedureName,
				};
			});
	}, [procedures]);

	React.useEffect(() => {
		selectedRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
	}, [selectedIndex]);

	return (
		<BoxFancyScroll className={classes.options}>
			{options.map(({ value, label }, index) => (
				<MenuItem
					className={classes.optionItem}
					key={value}
					onClick={() => onClick(value)}
					selected={index === selectedIndex}
					ref={index === selectedIndex ? selectedRef : undefined}
				>
					{label}
				</MenuItem>
			))}
		</BoxFancyScroll>
	);
};
