import React from 'react';
import { Button, Flex } from '@ascension/web';
import { useFormContext } from 'react-hook-form';
import { hospitalsListFromSavedUnits, practicesListFromSavedSurgeons } from '../../../utilities/filtersUtilities';
import { useDispatch, useSelector } from 'react-redux';
import { updateHospitalsInHospitalUserProfile, updatePracticesInOfficeUserProfile } from '../../../store/actions/UserProfileActionCreators';
import { ToastType } from '../../../interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '../../../utilities/toastConstants';
import { useToast } from '../../../utilities/hooks/useToast/useToast';
import {
	UserProfileHasARoleSelector,
	UserProfilePrimaryHospitalSelectorId, UserProfilePrimaryPracticeSelectorId
} from '../../../store/selectors/UserProfileSelector';
import { OfficeRoles } from '../../../interfaces/UserProfile/UserRoleEnum';
import { RootState } from '../../../interfaces/rootState';
import { FullSurgeonsSelector } from '../../../store/selectors/SurgeonListSelector';
import { StickyContainer } from '../../StickyContainer/StickyContainer';
import FormError from '../../FormComponents/FormError/FormError';
import { MyFiltersDefaultValues  as defaultFilters } from '../../AccountSettings/MyFilters/MyFiltersDefaultValues';
import { useSaveFiltersContext } from '../../AccountSettings/MyFilters/SaveFiltersContext/SaveFiltersContext';
import { MyFiltersState } from '../../../interfaces/MyFilters/MyFiltersState';
import { useHistory } from 'react-router-dom';

export default function MyFiltersSetupButtons() {
	const dispatch = useDispatch();
	const displayToast = useToast();
	const history = useHistory();
	const { isFormStateSameAsDefaultState } = useSaveFiltersContext();
	const { getValues, formState: { errors } } = useFormContext();

	const isOfficeRole = useSelector(UserProfileHasARoleSelector(OfficeRoles));
	const primaryHospitalId = useSelector(UserProfilePrimaryHospitalSelectorId);
	const primaryPracticeId = useSelector(UserProfilePrimaryPracticeSelectorId);
	const fullUnitList = useSelector((state: RootState) => state.unitList.groupedUnitList);
	const fullSurgeonList = useSelector(FullSurgeonsSelector);

	const submitFiltersStateToApi = React.useCallback( (isSetupLater: boolean) => {
		const formState: MyFiltersState = isSetupLater ? defaultFilters : getValues();
		const { unit: selectedUnits, surgeon: selectedSurgeons } = formState;
		const onSuccessCallback = () => {
			if (isSetupLater) history.push('/', { disableClearToast: true });
			else {
				displayToast(ToastType.SUCCESS, ToastConstants.SUCCESSFULLY_SAVED_FILTERS);
				history.push('/', { disableClearToast: true });
			}
		};
		const onFailureCallback = () => displayToast(ToastType.ERROR, ToastConstants.SAVE_ERROR);

		if (isOfficeRole && selectedSurgeons) {
			const practices = practicesListFromSavedSurgeons(selectedSurgeons, fullSurgeonList, primaryPracticeId);
			dispatch(updatePracticesInOfficeUserProfile(formState, practices, onSuccessCallback, onFailureCallback));
		}
		else if (!isOfficeRole && selectedUnits) {
			const hospitals = hospitalsListFromSavedUnits(selectedUnits, fullUnitList, primaryHospitalId);
			dispatch(updateHospitalsInHospitalUserProfile(formState, hospitals, onSuccessCallback, onFailureCallback));
		}
	}, [dispatch, displayToast, fullSurgeonList, fullUnitList, getValues, isOfficeRole, primaryHospitalId, primaryPracticeId, history]);

	const handleSetupLater = () => {
		submitFiltersStateToApi( true);
	};

	const handleSaveFilters = () => {
		submitFiltersStateToApi( false);
	};

	const isInvalid = React.useMemo(() => !!Object.keys(errors).length, [errors]);

	return (
		<StickyContainer>
			{isInvalid && <FormError>Please check the page for errors.</FormError>}
			<Flex
				alignItems="center"
				backgroundColor="#FFF"
				justifyContent="flex-end"
				flexDirection="row"
			>
				<Button
					secondary
					className="margin-right-10"
					id="setupLater"
					onClick={handleSetupLater}
				>
                    SET UP LATER
				</Button>
				<Button
					id="saveInfo"
					disabled={isFormStateSameAsDefaultState || isInvalid}
					onClick={handleSaveFilters}
				>
                    SAVE
				</Button>
			</Flex>
		</StickyContainer>
	);
}
