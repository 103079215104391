import {
	RequestFormValidatedState,
	WebRequestFormValidatedState
} from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { SurgeryProcedureInterface } from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import { InsuranceApi, PreAuthOptionsEnum } from '@interfaces/SurgeryRequest/InsuranceApi';
import {
	SurgeryDocumentRequestNonHold,
	SurgeryRequest,
	SurgeryRequestNonHold
} from '@interfaces/SurgeryRequest/SurgeryRequest';
import { ProcedureApi } from '@interfaces/Procedure/ProcedureApi';
import { DiagnosisApi } from '@interfaces/SurgeryRequest/DiagnosesApi';
import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';
import { removeMaskSpace } from '@utilities/formUtilities';
import surgeryProcedureToProcedureApiConverter from '@utilities/typeConverters/surgeryProcedureToProcedureApiConverter';
import { YesNoAnswer } from '@interfaces/SurgeryRequest/YesNoAnswer';
import { convertAndFormatDateToApi, dateToInputString } from '@utilities/dateUtilities';
import SupplementalRequestInfo from '@data/request/SupplementalRequestInfo';
import { addMinutes, format } from 'date-fns';
import { AppointmentType } from '@data/request/AppointmentType';
import DocumentRequestFormType from '@interfaces/RequestForm/forms/DocumentRequestFormType';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';

export function isCommonRequestFormType(obj: unknown): obj is CommonRequestForm {
	return !!(obj && typeof obj === 'object' && 'appointmentType' in obj);
}
export function isDocumentRequestFormType(obj: unknown): obj is DocumentRequestFormType {
	return isCommonRequestFormType(obj) && obj.appointmentType === AppointmentType.DOCUMENT;
}

export function isWebRequestFormType(obj: unknown): obj is WebRequestFormValidatedState {
	return isCommonRequestFormType(obj) && (obj.appointmentType === AppointmentType.WEB || obj.appointmentType === AppointmentType.LEGACY_WEB);
}

export const RequestValidatedToApi = (
	validated: RequestFormValidatedState,
	{
		practiceId,
		hospitalId,
		hospitalTimeZone,
		practiceName,
	}: SupplementalRequestInfo,
	originalDetails?: SurgeryRequestGet
): SurgeryRequestGet | SurgeryRequest | Record<string, never> => {
	if (!validated || !Object.keys(validated).length) {
		return {};
	}

	const unitId = validated.appointmentLocation;

	const splitTime = validated.procedureTime[0].split(':');
	const dateTimeCombined = new Date(validated.procedureDate);
	dateTimeCombined.setHours(Number(splitTime[0]), Number(splitTime[1]));
	const convertedProcDateTime = convertAndFormatDateToApi(dateTimeCombined, hospitalTimeZone);

	let reqNonHold: SurgeryRequestNonHold | SurgeryDocumentRequestNonHold;

	if (isDocumentRequestFormType(validated)) {
		reqNonHold = {
			appointmentType: validated.appointmentType,
			hospital: hospitalId,
			hospitalTimeZone: hospitalTimeZone,
			unit: unitId,
			room: validated.room,
			practice: practiceId,
			appointmentDetail: {
				toFollow: validated.appointmentToFollow,
				blockTime: validated.appointmentBlockTime,
				authStatus: '',
				duration: validated.duration,
				procedureDate: convertedProcDateTime,
			},
			patientInfo: {
				name: validated.patientFirstName,
				middleName: validated.patientMiddleName,
				family: validated.patientLastName,
			},
			procedures: [{
				cptCodes: validated.cptCodes.entriesList || [],
				surgeonId: validated.primarySurgeon,
				name: validated.procedureName,
				primary: true,
			}],
			surgeryInformation: {
				diagnosis: [{
					code: validated.primaryDiagnosis,
					primary: true,
					description: validated.primaryDiagnosis,
				}]
			},
			insurances: [{
				preCert: validated.preCertNumberPrimary,
			}],
		};
	} else {
		// DOB not on form at the moment but required by API, will provide default for now
		const patientDOBForApi = dateToInputString(validated.patientDOB || new Date(0))
			.replace(/\s/g, '');
		const insurance: InsuranceApi[] = [
			{
				family: validated.insuredLastNamePrimary,
				groupNumber: validated.groupNumberPrimary,
				name: validated.insuredFirstNamePrimary,
				planName: validated.healthPlanNamePrimary,
				policyNumber: validated.policyNumberPrimary,
				preAuth: validated.preAuthPrimary,
				preCert: validated.preCertNumberPrimary,
				primary: true,
			},
		];

		if (validated.insuredLastNameSecondary) {
			insurance.push({
				family: validated.insuredLastNameSecondary,
				groupNumber: validated.groupNumberSecondary as string,
				name: validated.insuredFirstNameSecondary as string,
				planName: validated.healthPlanNameSecondary as string,
				policyNumber: validated.policyNumberSecondary as string,
				preAuth: validated.preAuthSecondary as PreAuthOptionsEnum,
				preCert: validated.preCertNumberSecondary as string,
				primary: false,
			});
		}
		const procedures: ProcedureApi[] = validated.surgeryProcedures ? [...validated.surgeryProcedures]
			.sort((procedureA: SurgeryProcedureInterface) =>
				// Primary should be sorted to first
				procedureA.isPrimaryProcedure ? -1 : 1)
			.map((procedure: SurgeryProcedureInterface): ProcedureApi => surgeryProcedureToProcedureApiConverter(procedure)) : [];

		const diagnosis: DiagnosisApi[] = [{
			code: validated.primaryDiagnosis,
			primary: true,
			description: validated.primaryDiagnosis,
		}];

		if (validated.secondaryDiagnosis) {
			diagnosis.push({
				code: validated.secondaryDiagnosis,
				primary: false,
				description: validated.secondaryDiagnosis,
			});
		}

		const getPatLocation = (validatedState: RequestFormValidatedState) => {
			// it is not possible to hit this 1st condition, but it is required to assert the type as WebRequestFormValidatedState
			if (isDocumentRequestFormType(validatedState)) { return; }

			if (!validatedState.patLocation || !validatedState.patLocation.value) {
				return '';
			}

			if (validatedState.patLocation.value === 'Other' && validatedState.patOtherLocation && validatedState.patOtherLocation.length) {
				return validated.patOtherLocation;
			}

			return validatedState.patLocation.value;
		};
		reqNonHold = {
			appointmentType: validated.appointmentType,
			appointmentDetail: {
				authStatus: validated.appointmentRequestAuthStatus ? validated.appointmentRequestAuthStatus.value : '',
				blockTime: validated.appointmentBlockTime,
				procedureDate: convertedProcDateTime,
				toFollow: validated.appointmentToFollow,
				duration: validated.duration,
			},
			hospital: hospitalId,
			insurances: insurance,
			patDetail: {
				bedNeeded: validated.patBedNeeded ? validated.patBedNeeded.value : 'None',
				location: getPatLocation(validated),
				preAdmissionTestingNeeded: validated.patTestingNeeded === YesNoAnswer.YES.toString(),
			},
			patientInfo: {
				birthDate: patientDOBForApi,
				family: validated.patientLastName,
				middleName: validated.patientMiddleName,
				name: validated.patientFirstName,
				email: validated?.patientEmail,
				gender: validated.patientGender?.value,
				interpreter: validated.patientInterpreterNeeded,
				interpreterLanguage: validated.patientInterpretLanguage,
				phone: removeMaskSpace(validated.patientPhoneNumber),
				ssn: removeMaskSpace(validated.patientSSN),
				iodineAllergy: validated.patientIodineAllergy,
				latexAllergy: validated .patientLatexAllergy,
				otherPatientDetails: validated.otherPatientDetails,
			},
			practice: practiceId,
			procedures: procedures,
			surgeryInformation: {
				comments: validated.otherSurgicalComments,
				diagnosis,
			},
			unit: unitId,
			room: validated.room,
			address: {
				addressLine1: validated.patientAddressLine1,
				addressLine2: validated.patientAddressLine2,
				city: validated.patientCity,
				state: validated.patientState,
				zipCode: validated.patientZipCode,
			},
		};

		let convertedPatDateTime = '';

		if (validated.patDate) {
			const dateTime = new Date(validated.patDate);
			const patTime = validated.patTime;
			if (patTime && patTime[0]) {
				const splitTime = patTime[0].split(':');
				dateTime.setHours(Number(splitTime[0]), Number(splitTime[1]));
			}
			convertedPatDateTime = convertAndFormatDateToApi(dateTime, hospitalTimeZone);
		}

		if (convertedPatDateTime.length && reqNonHold.patDetail) {
			reqNonHold.patDetail.date = convertedPatDateTime;
		}
	}

	let req: SurgeryRequest;

	if (validated.requestRevisionMessage || validated.requestRevisionReason) {
		reqNonHold['revisionMessage'] = {
			reason: validated.requestRevisionReason,
			message: validated.requestRevisionMessage
		};
	}

	if (validated.timeToSchedule) {
		reqNonHold.timeToSchedule = validated.timeToSchedule;
	}

	if (validated.holdEventId) {
		const holdEvent = {
			id: validated.holdEventId,
			hospitalId,
			unitId,
			hospitalTimeZone,
			roomId: validated.roomId || '',
			room: validated.room || '',
			startTime: convertedProcDateTime,
			endTime: convertAndFormatDateToApi(addMinutes(dateTimeCombined, Number(validated.duration)), hospitalTimeZone),
			date: format(dateTimeCombined, 'yyyy-MM-dd'),
			description: practiceName,
		};
		req = {
			...reqNonHold,
			holdEvent,
		};
	} else {
		req = reqNonHold;
	}
	if (validated.documents && validated.appointmentType !== AppointmentType.LEGACY_WEB) {
		req.documents = validated.documents;
	}
	if (originalDetails) {
		const modifiedReq = {
			...originalDetails,
			attachments: undefined,
			holdEvent: undefined,
			...req,
		};

		delete modifiedReq['attachments'];
		delete modifiedReq['holdEvent'];

		return modifiedReq;
	}

	return req;
};
