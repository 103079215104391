import React from 'react';
import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StatefulTextInput } from '@components/StatefulInputs/StatefulTextInput/StatefulTextInput';
import { PatientDetailsValidationSchema } from '@components/RequestForm/PatientDetails/PatientDetailsValidationSchema';
import { StatefulSelect } from '@components/StatefulInputs/StatefulSelect/StatefulSelect';
import {
	ADDRESS_STATE_OPTIONS,
} from '@components/RequestForm/PatientDetails/utilities/stateOptions';
import { ZipCode } from '@components/RequestForm/PatientDetails/PatientAddress/ZipCode/ZipCode';
import {
	useCheckAddressValidationRequired
} from '@components/RequestForm/utilities/hooks/useCheckAddressValidationRequired/useCheckAddressValidationRequired';

const useStyles = makeStyles((theme) => (createStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(4),
		width: '100%',
		margin: theme.spacing(4, 0),
	},
	addressRow: {
		display: 'grid',
		gridTemplateColumns: '24fr 12fr 13fr',
		gap: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: '1fr',
			gap: theme.spacing(4),
		},
	},
})));

const FIELD_NAME_ADDRESS_LINE_1 = 'patientAddressLine1';
const FIELD_NAME_ADDRESS_LINE_2 = 'patientAddressLine2';
const FIELD_NAME_CITY = 'patientCity';
const FIELD_NAME_STATE = 'patientState';

export const PatientAddress = () => {
	const classes = useStyles();
	const { shouldFieldsBeRequired } = useCheckAddressValidationRequired();

	return <Box className={classes.root}>
		<StatefulTextInput
			name={FIELD_NAME_ADDRESS_LINE_1}
			label="Address 1"
			styleAsRequired={shouldFieldsBeRequired}
			controllerOptions={{
				rules: {
					...PatientDetailsValidationSchema[FIELD_NAME_ADDRESS_LINE_1],
					required: shouldFieldsBeRequired ? PatientDetailsValidationSchema[FIELD_NAME_ADDRESS_LINE_1].required : false,
				},
			}}
		/>

		<StatefulTextInput
			name={FIELD_NAME_ADDRESS_LINE_2}
			label="Address 2"
			controllerOptions={{
				rules: PatientDetailsValidationSchema[FIELD_NAME_ADDRESS_LINE_2]
			}}
		/>

		<Box className={classes.addressRow}>
			<StatefulTextInput
				name={FIELD_NAME_CITY}
				label="City"
				styleAsRequired={shouldFieldsBeRequired}
				controllerOptions={{
					rules: {
						...PatientDetailsValidationSchema[FIELD_NAME_CITY],
						required: shouldFieldsBeRequired ? PatientDetailsValidationSchema[FIELD_NAME_CITY].required : false,
					},
				}}
			/>

			<StatefulSelect
				name={FIELD_NAME_STATE}
				label="State"
				styleAsRequired={shouldFieldsBeRequired}
				controllerOptions={{
					rules: {
						...PatientDetailsValidationSchema[FIELD_NAME_STATE],
						required: shouldFieldsBeRequired ? PatientDetailsValidationSchema[FIELD_NAME_STATE].required : false,
					},
				}}
				options={ADDRESS_STATE_OPTIONS}
			/>
			<ZipCode />
		</Box>
	</Box>;
};
