import React, { useEffect } from 'react';
import {
	Accordion,
	Box,
	AccordionSummary,
	Typography,
	makeStyles,
	createStyles,
	AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FindATimeAccordionProps } from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeAccordionProps';
import { FindATimeResults } from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeResults/FindATimeResults';
import { useFindATimeContext } from '@components/FindATimeContext/FindATimeContextProvider';
import {
	useDoesUnitSupportOpenTime
} from '@components/RequestForm/utilities/hooks/useDoesUnitSupportOpenTime/useDoesUnitSupportOpenTime';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'border': `1px solid ${theme.palette.grey[500]}`,
			'borderRadius': '4px',
			'& .MuiAccordion-root': {
				'& .MuiAccordionSummary-root': {
					minHeight: '32px',
					height: '32px',
				},
				'& .MuiAccordionSummary-root.Mui-disabled': {
					'opacity': 1,
					'color': theme.palette.grey[500],
					'& .MuiSvgIcon-root': {
						color: theme.palette.grey[500],
					},
				},
				'& .MuiAccordionSummary-expandIcon.Mui-expanded': {
					transform: 'rotate(90deg)',
				},
			},
			'& .MuiPaper-root': {
				boxShadow: 'none',
				border: 'none',
			},
			'& .MuiAccordionDetails-root': {
				maxHeight: '528px',
			}
		},
		accordionSummaryContainer: {
			paddingLeft: theme.spacing(3),
			color: theme.palette.primary.main,
		},
		accordionSummary: {
			fontWeight: 600,
		},
		expandIcon: {
			color: theme.palette.primary.main,
			transform: 'rotate(-90deg)',
		},
		accordionDetails: {
			borderTop: `1px solid ${theme.palette.grey[500]}`,
			borderRadius: '4px',
			boxShadow: '0px 0px 8px 0px rgba(15, 60, 151, 0.25) inset',
			background: theme.palette.common.white,
			// We need to turn off the accordion padding so that the fancy scroll in FindATimeResults can work properly.
			padding: 0,
		},
	}),
);

export const FindATimeAccordion = ({ disabled }: FindATimeAccordionProps) => {
	const classes = useStyles();
	const { accordionExpanded, findATimeResponse, setAccordionExpanded } = useFindATimeContext();
	const accordionDisabled = disabled || !findATimeResponse;
	const supportsOpenTime = useDoesUnitSupportOpenTime();

	const handleAccordionChange = () => {
		setAccordionExpanded((prevState) => !prevState);
	};

	useEffect(() => {
		if (findATimeResponse) {
			setAccordionExpanded(true);
		}
	}, [findATimeResponse, setAccordionExpanded]);

	if (!supportsOpenTime) {
		return null;
	}

	return (
		<Box className={classes.root}>
			<Accordion expanded={accordionExpanded} onChange={handleAccordionChange}>
				<AccordionSummary
					disabled={accordionDisabled}
					className={classes.accordionSummaryContainer}
					expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
				>
					<Typography variant="body2" className={classes.accordionSummary}>
						Select a time
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.accordionDetails}>
					<FindATimeResults />
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};
