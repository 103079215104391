import React from 'react';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import ErrorMessageMui from '@components/ErrorMessageMui/ErrorMessageMui';

const useStyles = makeStyles(theme=> createStyles ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(3),
	},
	title: {
		'fontSize': '1.5rem',
		'color': theme.palette.error.main,
		'fontWeight': 500,
		'alignItems': 'center',
		'marginBottom': theme.spacing(1),
		'& > .MuiSvgIcon-root': {
			height: '24px',
			width: '24px',
			margin: theme.spacing(-0.25, 1, 0, 0),
		}
	},
}));

export const NoResultsMessage = () => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<ErrorMessageMui displayIcon={true} icon={Error} errorMessage="No Results Found" className={classes.title}/>
			<Typography>
				Please change your search date or other criteria and try again.
			</Typography>
		</Box>
	);
};
