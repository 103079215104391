import React from 'react';
import { ProcedureNameValidation } from '@components/RequestForm/RequestFormValidation';
import { ArrowDropDown } from '@material-ui/icons';
import { ProcedureNameInput } from '@components/RequestForm/inputs/ProcedureName/ProcedureNameInput/ProcedureNameInput';
import { useAnchorContext } from '@components/AnchorProvider/AnchorProvider';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(() =>
	createStyles({
		procedureSelect: {
			'& .MuiOutlinedInput-input': {
				'textAlign': 'left',
				'&:hover': {
					cursor: 'pointer',
				},
			},
		},
		endAdornment: {
			cursor: 'pointer',
		},
	}),
);

export const ProcedureNameMainInput = () => {
	const classes = useStyles();

	const { handleSetAnchorEl, anchorEl } = useAnchorContext();
	const {
		selectedProcedure,
		triggerProcedureNameValidation,
		resetProcedureName,
		isCustomProcedure,
	} = useProcedureNameContext();

	const { watch } = useFormContext<RequestFormValidatedState>();
	const procedureName = watch('procedureName');

	const shouldValidateProcedureSelect = anchorEl === null && !isCustomProcedure;

	const handleOnBlur = React.useCallback(() => {
		if (isCustomProcedure && !procedureName) {
			resetProcedureName();
			return;
		}

		if (shouldValidateProcedureSelect) {
			triggerProcedureNameValidation();
		}
	}, [isCustomProcedure, procedureName, resetProcedureName, shouldValidateProcedureSelect, triggerProcedureNameValidation]);

	return (
		<ProcedureNameInput
			label="Primary Procedure"
			validationRules={ProcedureNameValidation}
			shouldShowError={!isCustomProcedure}
			inputProps={{
				type: 'button',
				value: selectedProcedure?.procedureName,
				onClick: handleSetAnchorEl,
				onBlur: handleOnBlur,
				className: classes.procedureSelect,
				endAdornment: <ArrowDropDown className={classes.endAdornment} fontSize="small" />,
			}}
		/>
	);
};
