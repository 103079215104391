import React, { Suspense } from 'react';
import './Main.css';
import ThemeProvider from '@ascension/web/components/common/ThemeProvider';
import configureStore from './store';
import { Provider } from 'react-redux';
import { Routes } from './Routes';
import AlertModal from './components/AlertModal/AlertModal';
import { MuiThemeProvider } from '@material-ui/core';
import theme from './theme/theme';
import ErrorStatusModal from './components/ErrorStatusModal/ErrorStatusModal';
import '@ascension/ui-library/dist/fonts/WhitneySSm.css';
import '@ascension/ui-library/dist/fonts/ChronicleSSm.css';

const App: React.FC = () => {
	return (
		<Provider store={configureStore()}>
			<div className="App">
				<MuiThemeProvider theme={theme}>
					<ThemeProvider>
						<Suspense fallback={<p>Loading...</p>}>
							<ErrorStatusModal />
							<AlertModal />
							<Routes />
						</Suspense>
					</ThemeProvider>
				</MuiThemeProvider>
			</div>
		</Provider>
	);
};

export default App;
