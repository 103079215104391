export interface NavOptionProps {
	route: string;
	label: string;
}

export const NAV_CONFIG_OPTIONS: NavOptionProps[] = [
	{
		route: '/caselist',
		label: 'Case List',
	},
	{
		route: '/scheduling',
		label: 'Scheduling',
	},
];
