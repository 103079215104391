import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import DocumentRequestFormType from '@interfaces/RequestForm/forms/DocumentRequestFormType';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import React from 'react';

const useGetSelectedUnitInfo = () => {
	const { watch } = useFormContext<RequestFormValidatedState | DocumentRequestFormType>();
	const [ appointmentLocation ] = watch(['appointmentLocation']);
	const units = useCollection<Unit>(ENDPOINT_USER_UNITS);
	return React.useMemo(() => {
		return units?.find((u) => u.id === appointmentLocation);
	}, [appointmentLocation, units]);
};

export default useGetSelectedUnitInfo;
