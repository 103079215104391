import { RequestFormPage } from '@components/RequestForm/RequestFormPage';
import CaseTrackerList from '@components/CaseTrackerList/CaseTrackerList';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';
import { AppPage } from '@components/AppPage/AppPage';
import { Login } from '@components/Login/Login';
import { ConfirmSetup } from '@components/ConfirmSetup/ConfirmSetup';
import Review from '@components/Review/Review';
import AccountSettings from '@components/AccountSettings/AccountSettings';
import { CaseDetailsPageWrapper } from '@components/CaseDetails/CaseDetailsPageWrapper';
import { QueuePage } from '@components/QueuePage/QueuePage';
import {
	HospitalInteractiveRoles,
	OfficeInteractiveRoles,
	OfficeManagerRoles,
	OfficeRoles,
} from '@interfaces/UserProfile/UserRoleEnum';
import GlobalLoader from '@components/Loaders/GlobalLoader/GlobalLoader';
import TermsOfUsePage from '@components/TermsOfUse/TermsOfUsePage';
import AuthBase from '@components/AuthBase/AuthBase';
import { ProfileSetupPage } from '@components/ProfileSetup/ProfileSetupPage';
import { FiltersSetupPage } from '@components/ProfileSetup/FiltersSetupPage/FiltersSetupPage';
import { AppPageContextProvider } from '@components/AppPage/AppPageContext/AppPageContext';
import ManageUsersListPage from '@components/ManageUsersList/ManageUsersListPage';
import UserRegistrationPage from '@components/UserRegistrationPage/UserRegistrationPage';
import UnitCalendarPage from '@components/UnitCalendarPage/UnitCalendarPage';
import EditUserRegistrationPage from '@components/ManageUsersList/EditUserRegistrationPage/EditUserRegistrationPage';
import SchedulePage from '@components/SchedulePage/SchedulePage';
import { HomePage } from '@components/HomePage/HomePage';

export const Routes = () => (
	<BrowserRouter>
		<AuthBase>
			<GlobalLoader />
			<AppPageContextProvider>
				<Switch>
					<Route path="/login" component={Login} />
					<AppPage
						path="/termsOfUse"
						exact={true}
						hideDropDown={true}
						hideRequestButton={true}
						component={TermsOfUsePage}
						showHomeLink={false}
						hasAlert={false}
					/>
					<AppPage
						path="/setup/profile"
						exact={true}
						component={ProfileSetupPage}
						hideDropDown={true}
						hideRequestButton={true}
						hasAlert={true}
					/>
					<AppPage
						path="/setup/filters"
						exact={true}
						component={FiltersSetupPage}
						hideDropDown={true}
						hideRequestButton={true}
						hasAlert={true}
					/>
					<AppPage
						path="/accountSettings/:type"
						exact={true}
						component={AccountSettings}
						showHomeLink={true}
						hasAlert={true}
					/>
					<AppPage
						path="/request"
						component={RequestFormPage}
						hideRequestButton={true}
						onlyAllowRoles={OfficeInteractiveRoles}
						forbiddenRedirectTo="/"
						hasAlert={true}
					/>
					<AppPage
						path="/cases/:caseId"
						component={CaseDetailsPageWrapper}
						hasAlert={true}
					/>
					<AppPage
						path="/queue/:indexPosition"
						component={QueuePage}
						hasAlert={true}
					/>
					<AppPage
						path="/users/edit/:userId"
						component={EditUserRegistrationPage}
						hideRequestButton={false}
						onlyAllowRoles={OfficeManagerRoles}
						hasAlert={true}
					/>
					<AppPage
						path="/users/register"
						component={UserRegistrationPage}
						hideRequestButton={true}
						onlyAllowRoles={OfficeManagerRoles}
						hasAlert={true}
					/>
					<AppPage
						path="/users"
						component={ManageUsersListPage}
						hideRequestButton={false}
						onlyAllowRoles={OfficeManagerRoles}
						hasAlert={true}
					/>
					<AppPage
						path="/unit/calendar"
						component={UnitCalendarPage}
						onlyAllowRoles={HospitalInteractiveRoles}
						hasAlert={true}
					/>
					<AppPage
						path="/setup/review"
						component={Review}
						hideRequestButton={true}
					/>
					<AppPage
						path="/setup/complete"
						component={ConfirmSetup}
						hideRequestButton={true}
					/>
					<AppPage
						path="/scheduling"
						component={SchedulePage}
						onlyAllowRoles={OfficeRoles}
						hasAlert={true}
						hideRequestButton={true}
					/>
					<AppPage
						path="/caselist"
						component={CaseTrackerList}
						hasAlert={true}
						subHeaderTitle="Case List"
					/>
					<AppPage path="/" component={HomePage} />
				</Switch>
			</AppPageContextProvider>
		</AuthBase>
	</BrowserRouter>
);
