import React from 'react';
import { StatefulTextInput } from '@components/StatefulInputs/StatefulTextInput/StatefulTextInput';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { DurationValidation } from '@components/RequestForm/RequestFormValidation';
import { useGetRequestProcedureDuration } from '@components/RequestForm/utilities/hooks/useGetRequestProcedureDuration/useGetRequestProcedureDuration';
import { useRequestFormContext } from '@components/RequestForm/RequestForm';
import { useFindATimeContext } from '@components/FindATimeContext/FindATimeContextProvider';
import useGetSelectedUnitInfo
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetSelectedUnitInfo/useGetSelectedUnitInfo';
import useGetPrimarySurgeonNpi
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetPrimarySurgeonNpi/useGetPrimarySurgeonNpi';
import {
	useDoesUnitSupportOpenTime
} from '@components/RequestForm/utilities/hooks/useDoesUnitSupportOpenTime/useDoesUnitSupportOpenTime';

export const Duration = () => {
	const { watch, setValue } = useFormContext<RequestFormValidatedState>();
	const { isEdit } = useRequestFormContext();
	const { setDurationFallback } = useFindATimeContext();
	const selectedUnit = useGetSelectedUnitInfo();
	const primarySurgeonNpi = useGetPrimarySurgeonNpi();
	const hasOpenTime = useDoesUnitSupportOpenTime();

	const [procedureName] = watch(['procedureName']);

	const { hospital: hospitalId, id: unitId } = selectedUnit || {};

	const durationApi = useGetRequestProcedureDuration({
		hospitalId,
		unitId,
		procedure: procedureName,
		npi: primarySurgeonNpi,
	});

	React.useEffect(() => {
		if (!isEdit && hasOpenTime) {
			const durationStr = durationApi?.toString();
			setDurationFallback(durationStr);
			setValue('duration', durationStr, { shouldValidate: true });
		}
	}, [durationApi, hasOpenTime, isEdit, setDurationFallback, setValue]);

	return (
		<StatefulTextInput
			controllerOptions={{
				defaultValue: '',
				rules: DurationValidation,
			}}
			label="Total case duration in minutes"
			name="duration"
		/>
	);
};
