import React from 'react';
import { AxiosError } from 'axios';
import useGetSchedules from '@data/schedules/hooks/useGetSchedules';
import convertSchedulesResponseToUiData
	from '@components/SchedulePage/utilities/convertSchedulesResponseToUiData/convertSchedulesResponseToUiData';
import useGetSurgeonsById from '@utilities/hooks/useGetSurgeonsByNpi/useGetSurgeonsById';
import { dateToString } from '@utilities/dateUtilities';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';
import { Facility } from '@interfaces/Facility';
import { ScheduleColumnData } from '@data/schedules/types/ScheduleColumnData';

const useGetParsedSchedules = () => {
	const getSchedules = useGetSchedules();
	const [schedules, setSchedules] = React.useState<ScheduleColumnData[]>([]);
	const [apiError, setApiError] = React.useState<string | undefined>();
	const surgeonsById = useGetSurgeonsById();
	const [ lastUpdate, setLastUpdate ] = React.useState<string>('');

	const getApiBlocksAndProcessForUi = React.useCallback((selectedSurgeons: number[], selectedFacility: Facility, selectedDate: Date) => {
		const dateString = dateToString(selectedDate);

		// Still loading, ignore
		if (!selectedFacility.id)  {
			return;
		}

		// hospitalCernerId not enabled, show error
		if (!selectedFacility.hospitalCernerId)  {
			setApiError('400CID');
			return;
		}

		const unitNamesString = selectedFacility.units.map((u) => u.name).join(',');
		const unitIdsString = selectedFacility.units.map((u) => u.id).join(',');

		getSchedules({
			data: {
				npis: selectedSurgeons.join(','),
				date: dateString,
				units: unitNamesString,
				unitIds: unitIdsString,
				hospitalTimeZone: selectedFacility.hospitalTimeZone,
				hospital: selectedFacility.id,
				facility: selectedFacility.hospitalCernerId,
			},
			onSuccess: (response) => {
				setApiError(undefined);
				setSchedules(convertSchedulesResponseToUiData(response.data, surgeonsById, selectedDate, selectedFacility.hospitalTimeZone));
				setLastUpdate(new Date().toISOString());
			},
			onFailure: (error: AxiosError<ErrorStatusState>) => {
				if (!error) return setApiError(undefined);
				if (error.code === 'ERR_CANCELED') return setApiError(undefined);
				if (typeof error.response?.data?.errorCode === 'string') return setApiError(error.response.data.errorCode);

				// Should still show an error, but errorCode is unknown
				setApiError(' ');
			}
		});
	}, [getSchedules, surgeonsById]);

	return {
		getApiBlocksAndProcessForUi,
		schedules,
		apiError,
		lastUpdate,
	};
};

export default useGetParsedSchedules;
