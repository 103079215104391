import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { MenuItem, SvgIconTypeMap } from '@material-ui/core';
import { UserRole } from '@interfaces/UserProfile/UserRoleEnum';
import React from 'react';
import { useSelector } from 'react-redux';
import { UserProfileRoleSelector } from '@store/selectors/UserProfileSelector';
import { arraysOverlap } from '@utilities/commonFunction';
import { useAnchorContext } from '@components/AnchorProvider/AnchorProvider';

export interface LinkMenuOptionProps {
	id: string;
	label: string;
	icon: OverridableComponent<SvgIconTypeMap>;
	showForRoles?: UserRole[];
	shouldHide?: boolean;
	onClick: () => void;
	order: number;
	autoFocus?: boolean;
}

export const LinkMenuOption: React.FC<LinkMenuOptionProps> = ({ showForRoles, shouldHide, id, onClick, label, icon: Icon, autoFocus  }) => {
	const { handleClearAnchorEl } = useAnchorContext();

	const handleClick = () => {
		onClick();
		handleClearAnchorEl();
	};

	const userRoles = useSelector(UserProfileRoleSelector);

	if (shouldHide) return null;
	if (showForRoles && !arraysOverlap(userRoles, showForRoles)) return null;

	return (
		<MenuItem onClick={handleClick} data-field={id} autoFocus={autoFocus}>
			<Icon/>
			{label}
		</MenuItem>
	);
};
