import React from 'react';
import { StatefulTextInput } from '@components/StatefulInputs/StatefulTextInput/StatefulTextInput';
import { OutlinedInputProps } from '@material-ui/core';
import { RegisterOptions } from 'react-hook-form';
import { WebRequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';

interface ProcedureNameInputProps {
	label: string;
	inputProps?: OutlinedInputProps;
	validationRules?: RegisterOptions;
	shouldShowError?: boolean;
}

export const ProcedureNameInput = ({ label, inputProps, validationRules, shouldShowError }: ProcedureNameInputProps) => {
	return (
		<StatefulTextInput<WebRequestFormValidatedState>
			controllerOptions={{
				rules: validationRules,
			}}
			label={label}
			name="procedureName"
			shouldShowError={shouldShowError}
			styleAsRequired={true}
			textInputProps={{
				...inputProps,
			}}
		/>
	);
};
