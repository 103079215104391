import React from 'react';
import { useDispatch } from 'react-redux';
import { useGetProcedures } from '@data/procedures/hooks/useGetProcedures';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';
import { ProcedureNameInfo } from '@components/RequestForm/ProcedureName/ProcedureNameInfo';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { useAsyncCollection } from '@utilities/hooks/useAsyncCollection/useAsyncCollection';
import { setErrorStatus } from '@store/actions/ErrorStatusActionCreators';
import { doesUnitHaveOpenTime } from '@utilities/doesUnitHaveOpenTime';

export const useGetProcedureNames = () => {
	const dispatch = useDispatch();
	const unitsPromise = useAsyncCollection<Unit>(ENDPOINT_USER_UNITS);
	const getProcedures = useGetProcedures();
	const [procedures, setProcedures] = React.useState<ProcedureNameInfo[]>([]);

	const getProceduresFromApi = React.useCallback(async (unitId) => {
		const units = await unitsPromise;
		const selectedUnit = units.find((unit) => unit.id === unitId);

		// Still loading, ignore
		if (!selectedUnit?.id || !selectedUnit?.hospital) {
			return;
		}
		// doesUnitHaveOpenTime && NO cerner id ---> show error
		// doesUnitHaveOpenTime && cerner id exists ---> make API call
		if (doesUnitHaveOpenTime(selectedUnit)) {
			if (!selectedUnit?.hospitalCernerId)  {
				const customError: ErrorStatusState = {
					status: 400,
					errorCode: '400PNC',
					message: 'Missing hospitalCernerId for selected unit.',
					timestamp: new Date().getTime()
				};
				dispatch(setErrorStatus(customError));
			} else {
				getProcedures({
					data: {
						hospital: selectedUnit.hospital,
						unit: selectedUnit.id,
					},
					onSuccess: (response) => {
						const data = response.data;
						setProcedures(data);
					},
				});
			}
		}
	}, [dispatch, getProcedures, unitsPromise]);

	return {
		getProceduresFromApi,
		procedures,
	};
};
