import { FieldValues, RegisterOptions } from 'react-hook-form';
import {
	CUSTOM_PROCEDURE_NAME_REQUIRED,
	PRIMARY_PROCEDURE_NAME_NOT_SELECTED,
	PRIMARY_SURGEON_MISMATCH,
	PRIMARY_SURGEON_NOT_SELECTED, PROCEDURE_DURATION_MINIMUM
} from '@utilities/Validation/ValidationMessages';
import { SurgeryProcedureInterface } from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import { INVALID_DATE, INVALID_FUTURE_DATE } from '@utilities/Validation/ValidationMessages';
import { validators } from '@utilities/Validation/validators';
import { dateParse } from '@utilities/dateUtilities';

export const  PrimarySurgeonValidation: RegisterOptions = {
	required: PRIMARY_SURGEON_NOT_SELECTED,
	validate: {
		primarySurgeonMismatch: (value: string, formValues: FieldValues) => {
			if (!formValues['surgeryProcedures']) return true;

			const surgeryProcedureFormValues = formValues['surgeryProcedures'] as SurgeryProcedureInterface[];
			if (surgeryProcedureFormValues.length === 0) return true;

			const primaryProcedure = surgeryProcedureFormValues.find(procedure => procedure.isPrimaryProcedure === 1);
			if (!primaryProcedure) return true;

			const procedureSurgeon = primaryProcedure.surgeon.value;
			return value === procedureSurgeon ? true : PRIMARY_SURGEON_MISMATCH;
		},
	}
};

const dateValidator = (testDate: Date) => {
	return testDate && validators.dateValidator(dateParse(testDate)[0]) === null || INVALID_DATE;
};

export const UniversalRequestedDateValidation: RegisterOptions = {
	required: INVALID_DATE,
	validate: {
		validDate: dateValidator,
	}
};

export const NewRequestedDateValidation: RegisterOptions = {
	...UniversalRequestedDateValidation,
	validate: {
		...UniversalRequestedDateValidation.validate,
		futureDate: (testDate: Date) => {
			return testDate && new Date(testDate).getTime() > new Date().getTime() || INVALID_FUTURE_DATE;
		}
	}
};

export const ProcedureNameValidation: Partial<RegisterOptions> = {
	required: PRIMARY_PROCEDURE_NAME_NOT_SELECTED,
};

export const CustomProcedureNameValidation: Partial<RegisterOptions> = {
	required: CUSTOM_PROCEDURE_NAME_REQUIRED,
};

export const DurationValidation: Partial<RegisterOptions> = {
	min: {
		value: 45,
		message: PROCEDURE_DURATION_MINIMUM,
	},
};
