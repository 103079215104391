import React from 'react';
import { StatefulErrorProps } from '@components/StatefulInputs/Base/StatefulError/StatefulErrorProps';
import { ErrorMessage } from '@hookform/error-message';
import ErrorMessageMui from '@components/ErrorMessageMui/ErrorMessageMui';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	errorMessage: {
		'margin': theme.spacing(1, 0),
		'color': theme.palette.error.main,
		'display': 'flex',
		'alignItems': 'center',
		'fontSize': '0.875rem',
		'& > .MuiSvgIcon-root': {
			height: '24px',
			width: '24px',
			marginRight: '3px',
			marginTop: '-3px'
		},
	},
}));

export const StatefulError: React.FC<StatefulErrorProps> = ({ name }) => {
	const classes = useStyles();

	return <ErrorMessage name={name} render={({ message }) =>
		<ErrorMessageMui
			icon={ErrorOutlineIcon}
			displayIcon={true}
			errorMessage={message}
			className={classes.errorMessage}
		/>
	} />;
};
