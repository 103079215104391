import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns-tz';
import React from 'react';
import { FindATimeRequestParams } from '@data/findATime/types/FindATimeRequestParams';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import useGetPrimarySurgeonNpi
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetPrimarySurgeonNpi/useGetPrimarySurgeonNpi';
import useGetSelectedUnitInfo
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetSelectedUnitInfo/useGetSelectedUnitInfo';
import { FindATimeRequest } from '@data/findATime/types/FindATimeRequest';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { useFindATimeContext } from '@components/FindATimeContext/FindATimeContextProvider';

interface FindATimeForm extends Pick<CommonRequestForm,
	'appointmentLocation' |
	'procedureDate' |
	'duration'
> {}

const validate = (params: FindATimeRequestParams): boolean => {
	return !!params
		&& !!params.hospitalId
		&& !!params.unitId
		&& !!params.rooms
		&& !!params.startDate
		&& !!params.endDate
		&& !!params.startTime
		&& !!params.endTime
		&& !!params.dow
		&& !!params.duration
		&& !!params.npi;
};
/**
 * useFindATimeRequest - gathers form data and builds a FindATimeRequest.
 * This can be shared by other pages/forms because default request params
 * are provided where optional form inputs may not be present.
 *
 * @returns {FindATimeRequest} request with params derived from form inputs.
 * Check *`isValid`* for your logic (enable a button, etc.) before submitting.
 */
export const useFindATimeRequest = (): FindATimeRequest => {
	const { watch, getFieldState } = useFormContext<FindATimeForm>();
	const [procedureDate, duration] = watch(['procedureDate', 'duration']);
	const { durationFallback } = useFindATimeContext();
	//watches primarySurgeon for us
	const primarySurgeonNpi = useGetPrimarySurgeonNpi();
	//watches appointmentLocation for us
	const selectedUnitInfo = useGetSelectedUnitInfo();
	const procedureDateState = getFieldState('procedureDate');
	const { selectedProcedure } = useProcedureNameContext();

	return React.useMemo((): FindATimeRequest => {
		const selectedDateString = procedureDate && format(procedureDate, 'yyyy-MM-dd');
		const durationInt = duration ? Number.parseInt(duration) : durationFallback ? parseInt(durationFallback) : 60;
		const durationParam = isNaN(durationInt) ? 60 : durationInt;

		const fatRequestParams: FindATimeRequestParams = {
			hospitalId: selectedUnitInfo?.hospital,
			unitId: selectedUnitInfo?.id,
			rooms: selectedProcedure?.roomName || [],
			startDate: selectedDateString, // 'yyyy-MM-dd', '2024-04-16'
			endDate: selectedDateString,   // 'yyyy-MM-dd', '2024-04-16'
			startTime: '07:00', // 'HH:mm' - '14:00' to represent '02:00 PM'
			endTime: '16:00',   // 'HH:mm' - '14:00' to represent '02:00 PM'
			dow: [1, 2, 3, 4, 5],
			duration: durationParam,
			npi: primarySurgeonNpi,
		};
		const isValid = !procedureDateState.invalid && validate(fatRequestParams);

		return {
			data: fatRequestParams,
			isValid,
		};
	}, [procedureDate, duration, durationFallback, selectedUnitInfo?.hospital, selectedUnitInfo?.id, selectedProcedure?.roomName, primarySurgeonNpi, procedureDateState.invalid]);
};
