import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import UserRegistrationForm from '../../UserRegistrationPage/UserRegistrationForm/UserRegistrationForm';
import { AlertBox, Button } from '@ascension/ui-library';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const PAGE_WIDTH = '718px';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		'margin': theme.spacing(0, 'auto', 10, 'auto'),
		'width': PAGE_WIDTH,
		'& .MuiTypography-h1': {
			fontSize: '3rem',
			lineHeight: '3.5rem',
			margin: theme.spacing(2, 0),
		},
		'& .MuiTypography-h2': {
			fontSize: '1.5rem',
			lineHeight: '1.5rem',
			fontWeight: 450,
		},
	},
	linkButton: {
		'letterSpacing': '.7px',
		'marginTop': theme.spacing(2),
		'&.MuiButton-textPrimary:hover': {
			backgroundColor: 'unset',
			textDecoration: 'underline',
		},
	},
	icon: {
		paddingRight: theme.spacing(1),
	},
}));

const EditUserRegistrationPage: React.FC = () => {
	const classes = useStyles();
	const history = useHistory();

	return (
		<Box className={classes.pageContainer}>
			<Button
				translate="yes"
				variant="text"
				color="primary"
				onClick={() => history.push('/users')}
				className={classes.linkButton}
			>
				<KeyboardArrowLeftIcon className={classes.icon}/>
				MANAGE USERS
			</Button>
			<Typography variant="h1" component="h1">
				Edit user
			</Typography>
			<AlertBox
				translate="yes"
				severity="info"
				id="info-alert"
				leftBorderAccent={true}
				elevation={2}
			>
				Please note: changes to user information will be sent to your Provisioning
				Manager to approve prior to being finalized
			</AlertBox>
			<UserRegistrationForm isEdit={true}/>
		</Box>
	);
};

export default EditUserRegistrationPage;
