import React from 'react';
import { useHistory } from 'react-router-dom';
import { Location } from 'history';
import { NAVIGATION_WARN_MODAL } from '@components/Modal/modalConstants';
import { useModalContext } from '@components/Modal/ModalContextProvider';

interface RequestFormNavigationHandlerParams {
	shouldBlockNavigation: (location: Location) => boolean;
}

interface RequestFormNavigationHandlersReturnVals {
	handleStayOnPage: () => void;
	handleBlockedNavigation: (nextLocation: Location) => boolean;
	handleConfirmNavigationClick: () => void;
}

/**
 * Custom hook that provides navigation handler functions for the request form.
 *
 * @param {RequestFormNavigationHandlerParams} params - The parameters for the hook.
 * @property {Function} params.shouldBlockNavigation - Function that determines whether navigation should be blocked.
 *
 * @returns {Object} An object containing the following properties:
 * - `handleStayOnPage`: Function to handle the event when the user chooses to stay on the page.
 * - `handleBlockedNavigation`: Function to handle the event when navigation is blocked.
 * - `handleConfirmNavigationClick`: Function to handle the event when the user confirms navigation.
 */
export const useRequestFormNavigationHandlers = (
	{ shouldBlockNavigation }: RequestFormNavigationHandlerParams
): RequestFormNavigationHandlersReturnVals => {
	const { closeModal, openModal } = useModalContext();
	const [redirectLocation, setRedirectLocation] = React.useState<Location | null>(null);
	const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);
	const history = useHistory();

	const navigate = React.useCallback((path: string) => {
		history.push(path);
	}, [history]);

	const handleStayOnPage = React.useCallback(() => {
		closeModal(NAVIGATION_WARN_MODAL);
	}, [closeModal]);

	const handleBlockedNavigation = React.useCallback((nextLocation: Location): boolean => {
		if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
			openModal(NAVIGATION_WARN_MODAL);
			setRedirectLocation(nextLocation);
			return false;
		}
		return true;
	}, [confirmedNavigation, shouldBlockNavigation, openModal]);

	const handleConfirmNavigationClick = React.useCallback(() => {
		closeModal(NAVIGATION_WARN_MODAL);
		setConfirmedNavigation(true);
	}, [closeModal]);

	React.useEffect(() => {
		if (confirmedNavigation && redirectLocation) {
			navigate(redirectLocation.pathname);
		}
	}, [confirmedNavigation, redirectLocation, navigate]);

	return { handleStayOnPage, handleBlockedNavigation, handleConfirmNavigationClick };
};
