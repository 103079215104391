import React from 'react';
import { Box, Button, Flex } from '@ascension/web';
import { useDispatch } from 'react-redux';
import { activateUserProfile } from '../../store/actions/UserProfileActionCreators';
import { useHistory } from 'react-router-dom';
import MyInformation from '../AccountSettings/MyInformation/MyInformation';
import usePageEffects from '../../utilities/hooks/usePageEffects/usePageEffects';
import { useToast } from '../../utilities/hooks/useToast/useToast';
import { ToastType } from '../../interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '../../utilities/toastConstants';

export default function Review () {
	const dispatch = useDispatch();
	const history = useHistory();
	const displayToast = useToast();
	usePageEffects('Review');

	const onSuccessCallback = () => {
		history.push('/setup/complete');
		displayToast(ToastType.SUCCESS, ToastConstants.SAVE_SUCCESS);
	};

	const onFailureCallback = () => {
		displayToast(ToastType.ERROR, ToastConstants.SAVE_ERROR);
	};

	const onClickSubmit = () => {
		dispatch(activateUserProfile(onSuccessCallback, onFailureCallback));
	};

	return (
		<>
			<Flex flexDirection="row" justifyContent="center" alignItems="start">
				<Box width={[.9, .7]}>
					<h1>Review and Confirm Setup</h1>
				</Box>
			</Flex>
			<MyInformation isReview={true}/>
			<Flex flexDirection="row" justifyContent="center" alignItems="start">
				<Box  width={[.9, .7]} textAlign="right" my = {20}>
					<Button onClick={onClickSubmit} id="submit">Submit</Button>
				</Box>
			</Flex>
		</>
	);
}
