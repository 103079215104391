import React, { useContext, useEffect } from 'react';
import { ProcedureNameContextType } from '@components/RequestForm/ProcedureName/ProcedureNameContextType';
import { useGetProcedureNames } from '@components/RequestForm/utilities/hooks/useGetProcedureNames/useGetProcedureNames';
import { ProcedureNameInfo } from '@components/RequestForm/ProcedureName/ProcedureNameInfo';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';

export const CUSTOM_PROCEDURE_OPTION = 'Custom Procedure';

const ProcedureNameContext = React.createContext<ProcedureNameContextType | undefined>(undefined);

export const useProcedureNameContext = () => {
	const ctx = useContext(ProcedureNameContext);

	if (!ctx) {
		throw new Error(
			'useProcedureNameContext must be used in a child of ProcedureNameProvider.',
		);
	}

	return ctx;
};

function checkCustomProcedure(procedureName: string): boolean {
	return procedureName === CUSTOM_PROCEDURE_OPTION;
}

function searchAllTermsPresent(text: string, searchTerms: string[]) {
	const lowerCaseText = text.toLowerCase();
	return searchTerms.every((term) => lowerCaseText.includes(term.toLowerCase()));
}

export const ProcedureNameProvider: React.FC<{ unitId: string }> = ({ children, unitId }) => {
	const [isCustomProcedure, setIsCustomProcedure] = React.useState<boolean>(false);
	const [selectedProcedureName, setSelectedProcedureName] = React.useState<string>('');
	const { setValue, trigger, watch } = useFormContext<RequestFormValidatedState>();
	const previousProcedureName = React.useRef('');
	const [procedureFilter, setProcedureFilter] = React.useState<string>('');

	const { procedures, getProceduresFromApi } = useGetProcedureNames();
	const procedureNameRequestForm = watch('procedureName');

	const isProcedureOption = React.useCallback((option: string) => {
		return procedures.some(proc => proc.procedureName === option);
	}, [procedures]);

	// This effect sets the ProcedureName select menu to custom and properly sets the procedure name.
	useEffect(() => {
		if (!procedures.length || !procedureNameRequestForm) {
			return;
		}

		const shouldBeCustom = !isProcedureOption(procedureNameRequestForm);

		if (shouldBeCustom) {
			setSelectedProcedureName(CUSTOM_PROCEDURE_OPTION);
			setIsCustomProcedure(true);
			return;
		}
	}, [procedureNameRequestForm, isProcedureOption, procedures]);

	const filteredProcedures = React.useMemo(() => {
		return procedureFilter
			? procedures.filter((procedure) =>
				searchAllTermsPresent(procedure.procedureName, procedureFilter.split(' ')),
			)
			: procedures;
	}, [procedures, procedureFilter]);

	const getProcedures = React.useCallback(() => {
		unitId && void getProceduresFromApi(unitId);
	}, [unitId, getProceduresFromApi]);

	const selectedProcedure = React.useMemo(() => {
		if (checkCustomProcedure(selectedProcedureName)) {
			return { procedureName: selectedProcedureName };
		}

		return procedures.find(
			(procedure: ProcedureNameInfo) => procedure?.procedureName === procedureNameRequestForm,
		);
	}, [procedures, selectedProcedureName, procedureNameRequestForm]);

	const resetProcedureName = React.useCallback(() => {
		setValue('procedureName', '');
	}, [setValue]);

	const triggerProcedureNameValidation = React.useCallback(() => {
		void trigger('procedureName');
	}, [trigger]);

	const setCustomProcedure = React.useCallback((procedureName: string) => {
		setSelectedProcedureName(CUSTOM_PROCEDURE_OPTION);
		setValue('procedureName', procedureName);
	}, [setValue]);

	const handleSetSelectedProcedureName = React.useCallback((procedureName: string) => {
		if (!isCustomProcedure) {
			previousProcedureName.current = selectedProcedureName;
		} else {
			previousProcedureName.current = procedureNameRequestForm;
		}

		setSelectedProcedureName(procedureName);
		setIsCustomProcedure(checkCustomProcedure(procedureName));

		if (checkCustomProcedure(procedureName)) {
			resetProcedureName();
			return;
		}

		setValue('procedureName', procedureName);
	}, [procedureNameRequestForm, isCustomProcedure, resetProcedureName, selectedProcedureName, setValue]);

	React.useEffect(() => {
		getProcedures();
	}, [getProcedures]);

	return (
		<ProcedureNameContext.Provider
			value={{
				procedures: filteredProcedures,
				selectedProcedure,
				setSelectedProcedureName: handleSetSelectedProcedureName,
				isCustomProcedure,
				setCustomProcedure,
				resetProcedureName,
				triggerProcedureNameValidation,
				previousProcedureName,
				setProcedureFilter,
				isProcedureOption
			}}
		>
			{children}
		</ProcedureNameContext.Provider>
	);
};
