import React from 'react';
import { useFormMeta } from '@store/context/FormMetaContext';
import { useDropdownFormContext } from '@utilities/hooks/useDropdownFormContext/useDropdownFormContext';
import { Box, Dropdown } from '@ascension/web';
import { FieldValues } from 'react-hook-form';
import { FormComponentProps } from './FormComponentProps';
import { checkIsRequired } from '@utilities/formUtilities';
import { DropdownOption } from '@definitions/DropdownOption';
import { useFormContext } from 'react-hook-form';

const defaultOptions: DropdownOption[] = [];

function FormDropdown<T extends FieldValues>({
	name,
	placeholder,
	label,
	disabled,
}: FormComponentProps<T> & { disabled?: boolean }) {
	const { schema, options } = useFormMeta<T>();

	const providedOptions: DropdownOption[] = Array.isArray(options[name]) ? options[name] as DropdownOption[] : defaultOptions;

	const {
		clearErrors
	} = useFormContext<T>();

	const {
		setSelectedValue,
		dropdownOptions,
		setDropdownOptions,
		error,
		selectedDropdownOption,
	} = useDropdownFormContext(name, providedOptions, { validationRules: schema[name] });

	const isRequired = checkIsRequired(schema[name]);

	React.useEffect(() => {
		setDropdownOptions(providedOptions);
	}, [providedOptions, setDropdownOptions]);

	React.useEffect(() => {
		if (selectedDropdownOption &&
			selectedDropdownOption.value &&
			providedOptions.length > 0 &&
			!providedOptions.find((option) => option.value === selectedDropdownOption.value)
		) {
			setSelectedValue('');
			// added a timeout to prevent the error from being cleared before it is set
			setTimeout(function () {
				clearErrors(name);
			}, 0);
		}
	}, [clearErrors, selectedDropdownOption, name, setSelectedValue, providedOptions]);

	return (
		<Box width={1 / 2} mt={8} data-field={name}>
			<Dropdown
				label={label}
				options={dropdownOptions}
				name={name}
				required={isRequired}
				onSelect={setSelectedValue}
				value={selectedDropdownOption}
				selected={selectedDropdownOption}
				errorMessage={error}
				placeholder={placeholder}
				disabled={disabled}
			/>
		</Box>
	);
}

export default FormDropdown;
