import React from 'react';
import {
	ProcedureNameSelect
} from '@components/RequestForm/inputs/ProcedureName/ProcedureNameSelect/ProcedureNameSelect';
import {
	useChangePrimaryProcedureName
} from '@components/RequestForm/utilities/hooks/useChangePrimaryProcedureName/useChangePrimaryProcedureName';

export const RequestFormProcedureNameSelect = () => {
	const handlePrimaryProcedureNameChanged = useChangePrimaryProcedureName();

	return <ProcedureNameSelect onOptionClick={handlePrimaryProcedureNameChanged}/>;
};
