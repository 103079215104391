import { EquipmentListResponse } from '@data/equipment/types/EquipmentListResponse';
import { SelectOption } from '@components/StatefulInputs/StatefulSelect/StatefulSelectProps';

export const transformEquipmentDataToDropdownOptions = (data: EquipmentListResponse): SelectOption[] => {
	return data.map((item) => ({
		value: item.id,
		label: item.label,
	}));
};

export const transformDropdownOptionsToEquipmentApi = (options: SelectOption[]): EquipmentListResponse => {
	return options.map((item) => ({
		id: item.value,
		label: item.label,
	}));
};
