import React, { useMemo } from 'react';
import { UseTimeFormContextReturnInterface } from './UseTimeFormContextReturnInterface';
import { UseTimeFormContextConfigInterface } from './UseTimeFormContextConfigInterface';
import { timeParse } from '@utilities/dateUtilities';
import { useFieldFormContextWithPrevious } from '@utilities/hooks/useFieldFormContextWithPrevious/useFieldFormContextWithPrevious';
import { parseAndUpdateTimeState, parsePeriodAndUpdateTimeState, convertAndSet12HourTime } from '@utilities/timeUtils/timeUtils';

export const useTimeFormContext = (name: string, config: Partial<UseTimeFormContextConfigInterface> = {}): UseTimeFormContextReturnInterface => {
	const {
		currentValue: internalTimeValue,
		setCurrentValue: setCurrentTimeValue,
		error: timeError,
		triggerSelfValidation: triggerTimeValidation,
	} = useFieldFormContextWithPrevious<[string, string | undefined]>(name + 'Time', {
		validationRules: config.timePickerValidation
	});
	const {
		currentValue: currentDateValue,
		setCurrentValue: setCurrentDateValue,
		error: dateError,
		setErrorMessage: setDateErrorMessage,
		clearErrorMessage: clearDateErrorMessage,
		triggerSelfValidation: triggerDateValidation,
	} = useFieldFormContextWithPrevious<Date | undefined>(name + 'Date', {
		validationRules: config.datePickerValidation,
		triggerValidationOnChange: !config.isInitialMount
	});

	const internal24Time = internalTimeValue && internalTimeValue[0];
	const typedTime = internalTimeValue && internalTimeValue[1];

	const [currentTimeValue, currentPeriodValue] = useMemo(() => {
		const timeParsed = internal24Time && timeParse(internal24Time, currentDateValue);
		return [
			typedTime,
			timeParsed && timeParsed[1] ? timeParsed[1] : 'am',
		] as [string | undefined, 'am' | 'pm'];
	}, [internal24Time, currentDateValue, typedTime]);

	const {
		timeSetter = setCurrentTimeValue,
		dateSetter = setCurrentDateValue,
	} = config;

	const currDateValueRef = React.useRef(currentDateValue);
	currDateValueRef.current = currentDateValue;

	const setTimeValue = React.useCallback((timeUpdate?: string) =>
		parseAndUpdateTimeState(timeSetter, timeUpdate, currDateValueRef.current), [timeSetter]);
	const setPeriodValue = React.useCallback((periodUpdate?: string) =>
		parsePeriodAndUpdateTimeState(timeSetter, periodUpdate, currDateValueRef.current), [timeSetter]);
	const set24TimeValue = useMemo(() => convertAndSet12HourTime(timeSetter), [timeSetter]);

	return {
		currentTimeValue,
		currentPeriodValue,
		currentDateValue,
		setTimeValue,
		set24TimeValue,
		setDateValue: dateSetter,
		setPeriodValue,
		dateError,
		timeError,
		triggerDateValidation,
		triggerTimeValidation,
		setDateErrorMessage,
		clearDateErrorMessage,
	};
};
