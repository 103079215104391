import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns-tz';
import classNames from 'classnames';
import { AvailableTimeOption } from '@data/findATime/types/AvailableTimeOption';
import { useFindATimeContext } from '@components/FindATimeContext/FindATimeContextProvider';
import { useTimeAndDurationWatcher } from '@components/RequestForm/utilities/hooks/useTimeAndDurationWatcher/useTimeAndDurationWatcher';
import { useProcedureTimeSetter } from '@components/RequestForm/utilities/hooks/useProcedureTimeSetter/useProcedureTimeSetter';
import { timeStringAs12Hr } from '@utilities/dateUtilities';
import { getRandomString } from '@utilities/commonFunction';

const useStyles = makeStyles((theme) => ({
	blockTitle: {
		fontWeight: 600,
		color: theme.palette.primary.dark,
	},
	nonBlockTitle: {
		fontWeight: 600,
		color: theme.palette.secondary.dark,
	},
	resultCard: {
		'color': theme.palette.grey[900],
		'cursor': 'pointer',
		'border': `1px solid ${theme.palette.grey[600]}`,
		'borderRadius': theme.spacing(0.5),
		'padding': theme.spacing(1),
		'boxShadow': theme.shadows[6],
		'& p': {
			fontSize: '0.875rem',
		},
		'&[data-block="true"]:hover': {
			backgroundColor: theme.palette.primary[100],
		},
		'&[data-block="false"]:hover': {
			backgroundColor: theme.palette.secondary[100],
		},
	},
	selected: {
		'color': theme.palette.common.white,
		'&[data-block="true"]': {
			'backgroundColor': theme.palette.primary.dark,
			'& $blockTitle': {
				color: theme.palette.common.white,
			},
			'&:hover': {
				'backgroundColor': theme.palette.primary.dark,
				'color': theme.palette.common.white,
				'& $blockTitle': {
					color: theme.palette.common.white,
				},
			}
		},
		'&[data-block="false"]': {
			'backgroundColor': theme.palette.secondary.dark,
			'& $nonBlockTitle': {
				color: theme.palette.common.white,
			},
			'&:hover': {
				'backgroundColor': theme.palette.secondary.dark,
				'color': theme.palette.common.white,
				'& $nonBlockTitle': {
					color: theme.palette.common.white,
				},
			}
		},
	},
}));

interface ResultCardProps {
	time: AvailableTimeOption;
}

export const ResultCard: React.FC<ResultCardProps> = ({ time }) => {
	const timeSlotId = React.useMemo(() => `${getRandomString(10)}-${time.block ? 'B' : 'NB'}`, [time.block]);
	const classes = useStyles();
	const { selectedTimeSlot, setSelectedTimeSlot, lastSelectedTimeSlot } = useFindATimeContext();
	const { setTimeValue, setPeriodValue } = useProcedureTimeSetter();
	useTimeAndDurationWatcher(time, timeSlotId);

	const displayFormattedStartTime = timeStringAs12Hr(time.start);
	const displayFormattedEndTime = timeStringAs12Hr(time.end);
	const requestFormStartTime = format(new Date(`01-01-1970 ${time.start}`), 'hh:mm aaa');

	const selected = React.useMemo(() => {
		return selectedTimeSlot === timeSlotId;
	}, [selectedTimeSlot, timeSlotId]);

	const handleSelectTime = React.useCallback(() => {
		if (selectedTimeSlot !== timeSlotId) {
			const startTimeOnly = requestFormStartTime.split(' ')[0];
			const periodValue = requestFormStartTime.split(' ')[1];
			setTimeValue(startTimeOnly);
			setPeriodValue(periodValue);
		}

		setSelectedTimeSlot(timeSlotId);
		lastSelectedTimeSlot.current = timeSlotId;
	}, [
		requestFormStartTime,
		selectedTimeSlot,
		setPeriodValue,
		setSelectedTimeSlot,
		setTimeValue,
		timeSlotId,
		lastSelectedTimeSlot,
	]);

	return (
		<Box
			className={classNames(classes.resultCard, {
				[classes.selected]: selected,
			})}
			data-block={time.block}
			onClick={handleSelectTime}
			data-field={`findATime-resultCard-${timeSlotId}`}
		>
			<Typography
				className={classNames({
					[classes.blockTitle]: time.block,
					[classes.nonBlockTitle]: !time.block,
				})}
			>
				{time.block ? 'Block' : 'Non-Block'}
			</Typography>
			<Typography>
				{displayFormattedStartTime} - {displayFormattedEndTime}
			</Typography>
		</Box>
	);
};
