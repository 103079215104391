import React from 'react';
import { useHistory } from 'react-router-dom';

export const useNavigationHistory = () => {
	const history = useHistory();
	const [navigationHistory, setNavigationHistory] = React.useState<string[]>([history.location.pathname]);

	React.useEffect(() => {
		return history.listen((location) => {
			setNavigationHistory((prevHistory) => [...prevHistory, location.pathname]);
		});
	}, [history, setNavigationHistory]);

	const handleGoBack = React.useCallback(() => {
		if (navigationHistory.length > 1) {
			history.goBack();
		} else {
			history.push('/');
		}
	}, [history, navigationHistory]);

	return { handleGoBack };
};
