import React from 'react';
import { Box } from '@material-ui/core';
import { NoResultsMessage } from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeResults/NoResultsMessage/NoResultsMessage';
import { useFindATimeContext } from '@components/FindATimeContext/FindATimeContextProvider';
import { Results } from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeResults/Results/Results';
import BoxFancyScroll from '@components/BoxFancyScroll/BoxFancyScroll';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme)=> ({
	root: {
		width: '100%',
		overflowY: 'auto',
		padding:theme.spacing(3)
	},
}));

export const FindATimeResults = () => {
	const { findATimeResponse } = useFindATimeContext();
	const classes = useStyles();

	if (findATimeResponse && findATimeResponse.length > 0) {
		return (
			<BoxFancyScroll className={classes.root}>
				<Results data={findATimeResponse} />
			</BoxFancyScroll>
		);
	}

	return (
		<Box>
			<NoResultsMessage />
		</Box>
	);
};
