import { SelectOption } from '@components/StatefulInputs/StatefulSelect/StatefulSelectProps';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Surgeon } from '@data/surgeon/Surgeon';
import { ENDPOINT_SURGEONS } from '@utilities/apiConstants';
import { useMemo } from 'react';
import { getFormattedName } from '@utilities/commonFunction';

export const useGetSurgeonOptions = (): SelectOption[] => {
	const prefSurgeons = useCollection<Surgeon>(ENDPOINT_SURGEONS);

	return useMemo(() => {
		return prefSurgeons
			.map((surgeon) => ({
				value: surgeon.id,
				label: getFormattedName(surgeon),
			}))
			.sort(({ label: aLabel }, { label: bLabel }) => aLabel.localeCompare(bLabel));
	}, [prefSurgeons]);
};
