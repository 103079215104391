import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import CardSection from '../CardSection/CardSection';
import { RequestFormProps } from './RequestFormProps';
import CardSectionTitle from '../CardSection/CardSectionTitle/CardSectionTitle';
import RequestDetails from '@components/RequestForm/formSections/RequestDetails';
import useSetInitialAppointmentType from '@components/RequestForm/utilities/useSetInitialAppointmentType';
import MultiFormAdapter from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapter';
import ComposedSections from '@components/RequestForm/composedSections/ComposedSections/ComposedSections';
import useAutoMaintainHoldEvent
	from '@components/RequestForm/utilities/hooks/useAutoMaintainHoldEvent/useAutoMaintainHoldEvent';
import { ManageHoldEventContextProvider } from '@components/RequestForm/ManageHoldEventContext/ManageHoldEventContext';
import RequestFormPageState from '@components/RequestForm/RequestFormPageState';
import RequestFormContextType from '@components/RequestForm/RequestFormContextType';
import { useIsDirtyForm } from '@components/RequestForm/utilities/hooks/useIsDirtyForm/useIsDirtyForm';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { ProcedureNameProvider } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { FindATimeProvider } from '@components/FindATimeContext/FindATimeContextProvider';
import { RouteNavigationGuard } from '@components/RequestForm/RouteNavigationGuard/RouteNavigationGuard';
import {
	useBlockExternalNavigation
} from '@components/RequestForm/utilities/hooks/useBlockExternalNavigation/useBlockExternalNavigation';

const RequestFormContext = React.createContext<RequestFormContextType | null>(null);

export const useRequestFormContext = () => {
	const ctx = React.useContext(RequestFormContext);

	if (!ctx) { throw new Error('useRequestFormContext must be used in a child of RequestForm component.'); }

	return ctx;
};

export const RequestForm: React.FC<RequestFormProps> = ({ formId, children, ...props }) => {
	useAutoMaintainHoldEvent();
	const { location: { state } } = useHistory<RequestFormPageState>();
	const { formState, getValues, watch } = useFormContext<CommonRequestForm>();

	const { isEdit = false } = props;
	const selectedBlockColumn = state?.blockInfo;

	const [initialValues, setInitialValues] = React.useState<CommonRequestForm>();
	const currentValues = watch();
	const { appointmentLocation } = currentValues;

	React.useEffect(() => {
		setInitialValues(getValues());
	}, [getValues]);

	const isDirtyForm = useIsDirtyForm(currentValues, initialValues);
	const shouldBlockNavigation = isDirtyForm && !!appointmentLocation;

	useBlockExternalNavigation(shouldBlockNavigation);

	useSetInitialAppointmentType(!isEdit);

	return (
		<>
			<MultiFormAdapter shouldAutoUpdate={!isEdit} formId={formId}>
				<RequestFormContext.Provider value={{
					...props,
					isEdit,
					selectedBlockColumn,
					appointmentLocation,
				}}>
					<ManageHoldEventContextProvider>
						<ProcedureNameProvider unitId={appointmentLocation}>
							<FindATimeProvider>
								<Box>
									<CardSection sectionTitle={<CardSectionTitle title="Request Details" />}>
										<RequestDetails canChangeAppointmentType={!isEdit}/>
									</CardSection>
									{appointmentLocation && <>
										<ComposedSections />
										{children}
									</>}
								</Box>
							</FindATimeProvider>
						</ProcedureNameProvider>
					</ManageHoldEventContextProvider>
				</RequestFormContext.Provider>
			</MultiFormAdapter>
			<RouteNavigationGuard
				when={shouldBlockNavigation && !formState.isSubmitSuccessful}
				shouldBlockNavigation={() => shouldBlockNavigation}
			/>
		</>
	);
};
