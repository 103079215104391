import React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { FindATimeResponse } from '@data/findATime/types/FindATimeResponse';
import { ResultRow } from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeResults/Results/ResultRow';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
	}),
);

export const Results = ({ data }: { data: FindATimeResponse }) => {
	const classes = useStyles();

	const ResultRows = data.map((availableDateOption) => {
		return <ResultRow key={availableDateOption.date} option={availableDateOption} />;
	});

	return <Box className={classes.root}>{ResultRows}</Box>;
};
