import React, { useCallback, useEffect } from 'react';
import {
	MenuItem,
	Box, InputLabel,
} from '@material-ui/core';
import { useMask } from '@utilities/hooks/useMask/useMask';
import { makeStyles } from '@material-ui/core/styles';
import theme from '@theme/theme';
import DropdownInput from '@components/DropdownInput/DropdownInput';
import ErrorMessageMui from '@components/ErrorMessageMui/ErrorMessageMui';
import { CancelOutlined } from '@material-ui/icons';
import getRequestFormLabelStyle from '@components/RequestForm/utilities/getRequestFormLabelStyle';
import { TextField } from '@ascension/ui-library';

interface TimePickerProps {
	name: string;
	label?: string | undefined;
	value: string | undefined;
	disabled?: boolean;
	periodValue: string;
	onTimeChange: (newValue?: string) => void;
	onPeriodChange: (newValue: string) => void;
	onBlur?: () => void;
	errorMessage?: string;
	required?: boolean;
}

const useStyles = makeStyles({
	textInput: {
		'marginBottom': theme.spacing(2.5),
		'& .Mui-error': {
			'& fieldset': {
				borderWidth: theme.spacing(0.25),
			},
		}
	},
	text: {
		color: theme.palette.grey[900],
	},
	timePickerWrap: {
		'width': '100%',
		'display': 'flex',
		'flexDirection': 'column',
		'marginBottom': theme.spacing(1.5),
		'& .MuiOutlinedInput-root': {
			'height': '52px',
			'& fieldset': {
				borderColor: theme.palette.grey[500]
			},
			'&:hover fieldset': {
				borderColor: theme.palette.primary.main,
				borderWidth: theme.spacing(0.25)
			},
		},
		'& .MuiInputBase-root': {
			'&.Mui-disabled': {
				'backgroundColor': 'rgb(239, 240, 244)',
				'color': 'rgb(119, 119, 119)',
				'& fieldset': {
					borderColor: theme.palette.grey[500],
				},
				'& .MuiInputBase-input': {
					cursor: 'not-allowed'
				},
				'&:hover fieldset': {
					borderColor: theme.palette.grey[500],
					borderWidth: theme.spacing(.125)
				},
			},
		},
		'& .MuiAscensionDropdown-formControlDefault': {
			minWidth: 'unset',
			margin: theme.spacing(-2.375, 0, 0)
		},
	},
	inputFieldWrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: theme.spacing(2),
	},
	errorMessageWrap: {
		'position': 'absolute',
		'margin': theme.spacing(-2, 0, 0),
		'alignItems': 'center',
		'fontWeight': theme.typography.fontWeightBold,
		'fontSize': theme.typography.caption.fontSize,
		'height': '18px',
		'& > .MuiSvgIcon-root': {
			height: '18px',
			width: '18px',
			marginRight: theme.spacing(0.25),
			marginTop: theme.spacing(0)
		},
		'&.Mui-error': {
			color: theme.palette.error[600],
		},
	},
	labelText: {
		...getRequestFormLabelStyle(theme),
		textTransform: 'uppercase',
		paddingBottom: theme.spacing(1)
	},
	meridiemDropdown: {
		'& fieldset': {
			top: 0,
		},
	}
});

const TimePicker: React.FC<TimePickerProps> = ({
	name,
	label,
	value,
	disabled,
	periodValue,
	onTimeChange,
	onPeriodChange,
	onBlur,
	errorMessage,
	required
}) => {
	const { timePickerWrap, labelText, textInput, text, inputFieldWrapper, errorMessageWrap, meridiemDropdown } = useStyles();
	const defaultTimeValue = value ? value : '';
	const { value: inputValue, updateValue: updateMaskValue } = useMask('time', defaultTimeValue);
	const handleTimeChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		updateMaskValue(event.target.value);
	}, [updateMaskValue]);

	useEffect(() => {
		onTimeChange(inputValue ? inputValue : '');
	}, [inputValue, onTimeChange]);

	useEffect(() => {
		updateMaskValue(defaultTimeValue);
	}, [defaultTimeValue, updateMaskValue]);

	const handlePeriodChange = useCallback((newValue: string) => {
		onPeriodChange(newValue);
	}, [onPeriodChange]);

	return (
		<Box className={timePickerWrap}>
			{ label && <InputLabel required={required} className={labelText}>{label}</InputLabel> }
			<Box className={inputFieldWrapper}>
				<TextField
					translate="yes"
					type="text"
					variant="static"
					fullWidth
					placeholder="HH:MM"
					error={!!errorMessage}
					disabled={disabled}
					data-field={`${name}-input`}
					value={inputValue}
					onChange={handleTimeChange}
					onBlur={onBlur}
					className={textInput}
					InputProps={{
						className: text
					}}
				/>
				<DropdownInput
					value={periodValue}
					onChange={handlePeriodChange}
					disabled={disabled}
					fieldName="timePeriod"
					className={meridiemDropdown}
				>
					<MenuItem value="am">A.M.</MenuItem>
					<MenuItem value="pm">P.M.</MenuItem>
				</DropdownInput>
			</Box>
			<Box>
				<ErrorMessageMui icon={CancelOutlined} displayIcon={true} className={errorMessageWrap}>{errorMessage}</ErrorMessageMui>
			</Box>
		</Box>
	);
};

export default TimePicker;
