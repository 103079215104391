import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

interface HomeNavigationContextType {
	homeRoute: string;
	setHomeRoute: Dispatch<SetStateAction<string>>;
}

const HomeNavigationContext = React.createContext<null | HomeNavigationContextType>(null);

export const useHomeNavigationContext = () => {
	const ctx = useContext(HomeNavigationContext);
	if (!ctx) {
		throw new Error(
			'useHomeNavigationContext must be used within a child component of HomeNavigationContextProvider',
		);
	}
	return ctx;
};

export const HomeNavigationContextProvider: React.FC = ({ children }) => {
	const [homeRoute, setHomeRoute] = useState<string>('/');

	return (
		<HomeNavigationContext.Provider value={{ homeRoute, setHomeRoute }}>
			{children}
		</HomeNavigationContext.Provider>
	);
};
