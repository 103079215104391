import React from 'react';
import { SURGERY_LOCATION_CHANGED_MODAL } from '@components/Modal/modalConstants';
import {
	useProcedureNameContext
} from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { useFormContext } from 'react-hook-form';
import {
	WebRequestFormValidatedState
} from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { useMultiFormValueSetter } from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapter';

export const useChangeSurgeryLocation = () => {
	const { setSelectedProcedureName } = useProcedureNameContext();

	const {
		getValues,
		setValue,
		watch,
	} = useFormContext<WebRequestFormValidatedState>();
	const { openModal } = useModalContext();

	const { surgeryProcedures = [] } = getValues();
	const appointmentLocation = watch('appointmentLocation');

	const setSelectedUnitId = useMultiFormValueSetter('appointmentLocation');

	const hasPrimaryProcedure = React.useMemo(() => {
		return surgeryProcedures.some(proc => proc.isPrimaryProcedure);
	}, [surgeryProcedures]);

	const shouldOpenModal = React.useCallback((value: string) => {
		if (hasPrimaryProcedure && value !== appointmentLocation) {
			openModal(SURGERY_LOCATION_CHANGED_MODAL.id);
			return true;
		}
		return false;
	}, [appointmentLocation, hasPrimaryProcedure, openModal]);

	return React.useCallback((newLocation: string) => {
		const modalOpened = shouldOpenModal(newLocation);

		if (!appointmentLocation || !hasPrimaryProcedure) {
			setSelectedUnitId(newLocation);
		}

		if (newLocation !== appointmentLocation) {
			setSelectedProcedureName('');
			setValue('duration', '');
		}

		// When modal is opened return false to prevent the new location from being set
		return !modalOpened;
	}, [appointmentLocation, hasPrimaryProcedure, setSelectedProcedureName, setSelectedUnitId, setValue, shouldOpenModal]);
};
