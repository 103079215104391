import {
	Box, Flex, Text, Dropdown
} from '@ascension/web';
import React from 'react';
import { PaginationComponentProps } from './PaginationComponentProps';
import Pagination from 'react-js-pagination';
import { useFilterState } from '../Filters/FiltersContext/FiltersContext';

export default function PaginationComponent ({ displayPerPage, fontSize }: PaginationComponentProps) {
	const options = [
		{ value: '25', label: '25' },
		{ value: '50', label: '50' },
		{ value: '100', label: '100' },
		{ value: '1000000', label: 'ALL' },
	];
	const { page, size, totalCount, entityName, updateFilterProps } = useFilterState();
	const startNumber= page;
	const defaultPageSize = size;
	const totalCases = Number(totalCount);
	const displayStart = ((startNumber-1)*defaultPageSize)+1;
	const displaySize = totalCases < (defaultPageSize*(startNumber)) ? totalCases : defaultPageSize * startNumber;
	const defaultOptionValue = options.filter(option=> option.value === defaultPageSize.toString())[0];

	const handleChangeSize = (newValue: number)=> {
		updateFilterProps({ page: 1, size: Number(newValue) });
	};

	const handlePageChange = (pageNumber: number) => {
		updateFilterProps({ page: pageNumber });
		window.scrollTo(0, 0);
	};

	return (
		<Flex justifyContent="center" data-field="Pagination_Component" >
			<Box
				mt={8}
				flexDirection="column"
				width={1}
				elevated>
				<Flex width={1} alignItems="start">
					<Flex flexDirection="row" data-field="Data_Information" fontSize={fontSize}>
						<Flex flexDirection="column" maxWidth={180} >
							<Text display="block">Showing <strong>{displayStart} - {displaySize}</strong> of <strong>{totalCases}</strong> {entityName.toLowerCase()}</Text>
						</Flex>
						{displayPerPage &&
						<>
							<Flex flexDirection="column" maxWidth={150} pl="35px" pr="10px">
								<Text display="block">{entityName} per page</Text>
							</Flex>
							<Flex flexDirection="column" maxWidth={50} mt={-9}>
								<Box className={['pagination-dropdown']} data-field="Page_Options">
									<Dropdown
										options={options}
										onSelect={handleChangeSize}
										placeholder="Select one"
										selected={defaultOptionValue}
									/>
								</Box>
							</Flex>
						</>
						}
					</Flex>
					<Box flex="1" textAlign="right" width={500} mt={-9} data-field="Pagination_Cmp">
						<Pagination
							prevPageText="PREV"
							nextPageText="NEXT"
							firstPageText="< FIRST"
							lastPageText="LAST >"
							activePage={Number(startNumber)}
							itemsCountPerPage={Number(defaultPageSize)}
							totalItemsCount={totalCases}
							pageRangeDisplayed={5}
							onChange={handlePageChange}
						/>
					</Box>
				</Flex>
			</Box>
		</Flex>
	);
}
