import React from 'react';
import { createStyles, Grid } from '@material-ui/core';
import { Warning as WarningIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import CalendarErrorContainer from '@components/CalendarError/presentationalComponents/CalendarErrorContainer';
import CalendarErrorTitle from '@components/CalendarError/presentationalComponents/CalendarErrorTitle';
import CalendarErrorSubtitle from '@components/CalendarError/presentationalComponents/CalendarErrorSubtitle';
import CalendarErrorBody from '@components/CalendarError/presentationalComponents/CalendarErrorBody';
import CalendarErrorButton from '@components/CalendarError/presentationalComponents/CalendarErrorButton';
import { useSchedulePageContext } from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';

const useStyles = makeStyles(theme => createStyles({
	errorBody: {
		marginBottom: theme.spacing(2.5),
		textAlign: 'left',
	},
	errorContainer: {
		color: theme.palette.common.black,
		margin: '0 auto',
		maxWidth: '625px',
	},
	errorSubtitle: {
		marginBottom: theme.spacing(1),
	},
	errorTitle: {
		'color': theme.palette.error.main,
		'marginBottom': theme.spacing(1.5),
		'textAlign': 'left',
		'& > .MuiGrid-root > .MuiSvgIcon-root': {
			marginRight: theme.spacing(0.5),
		},
	},
}));

const UnableToLoadScheduleError: React.FC = () => {
	const classes = useStyles();
	const { apiError, getSchedules } = useSchedulePageContext();

	return (
		<CalendarErrorContainer className={classes.errorContainer} alignItems="flex-start" justifyContent="flex-start">
			<CalendarErrorTitle className={classes.errorTitle} variant="h5">
				<Grid container direction="row" alignItems="center" wrap="nowrap">
					<WarningIcon />
					There was a problem loading the block schedule
				</Grid>
			</CalendarErrorTitle>
			<CalendarErrorSubtitle className={classes.errorSubtitle}>
				Error Code: {apiError}
			</CalendarErrorSubtitle>
			<CalendarErrorBody className={classes.errorBody}>
				Please try reloading the page. If the issue persists, please contact support and supply the Error Code above.
			</CalendarErrorBody>
			<CalendarErrorButton onClick={getSchedules} color="primary">
				Reload
			</CalendarErrorButton>
		</CalendarErrorContainer>
	);
};

export default UnableToLoadScheduleError;
